import Button from '@mui/material/Button';
import { useState, useEffect, useContext} from 'react'
import styled from 'styled-components';
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'

import VotePopUp from './VotePopUp'

import {MainClaimContext} from './ClaimContext'

import CustomCircularProgress from './CustomCircularProgress';

import './ClaimClay.css'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WhatshotIcon from '@mui/icons-material/Whatshot';

  const ClaimButton = styled.button`
    position: relative;
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
    border:0px;    
    cursor: pointer;
    
  :before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 140%;
    background: linear-gradient(-180deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }

  @keyframes animate 
  {
    0%
    {
      transform: rotate(0deg);
    }
    100%
    {
      transform: rotate(360deg);
    }
  }
  :after
  {
    content: '';
    position: absolute;
    inset : 2px;
    background: linear-gradient(30deg, rgba(47,53,85,1) 0%, rgba(29,58,61,1) 100%);
    box-shadow: inset -7px -7px 38px rgba(0, 0, 0), inset 3px 1px 10px rgba(255, 255, 255, 0.4);
    border-radius: 18px;
    z-index: 1;
    border:0px;
  }

  :hover
  {
    position: relative;
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
  }


  :hover:before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 250%;
    background: linear-gradient(120deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }
  `

  const ButtonText = styled.h1`
    position: relative;
    font-size: 1.8em;
    font-family: 'shortstack';
    letter-spacing: 0.1em;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    color: #fff;
    z-index: 4;
  `







function ClaimClay(props) {

    const setKilnAnimation = props.setKilnAnimation

    const notify = (message) => toast(message);
    const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)

    
    const [testState, setTestState] = useState(0)
    


    //const id = toast.loading("Please wait...")

    const isAlertOpen = props.isAlertOpen 
    const setIsAlertOpen = props.setIsAlertOpen

    const submittedTx = props.submittedTx
    const setSubmittedTx = props.setSubmittedTx

    const setFlamesVisible = props.setFlamesVisible
    const flamesVisible = props.flamesVisible

    const hasBaking = props.hasBaking




    const notifyWarnCustom = (message) => toast(<div style={{height:'100% '}}><WhatshotIcon /> {message} </div>);


    const permError = (message) => toast.error(message, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });


    const permSuccess = (message) => toast.success(message, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });



    const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, isVoteOpen, voteOption, voteProposal, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, setIsVoteOpen, setVoteOption, setVoteProposal} = useContext(MainClaimContext)

    //const []

    const cn_info = {policy_id:'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728', base_name: 'ClayNation'}
    const gc_info = {policy_id:'94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768', base_name: "ClayCharlotte"}

    const eligible_info = {cn:{policy_id:'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728', base_name: 'ClayNation'},gc:{policy_id:'94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768', base_name: "ClayCharlotte"}}



    async function getVotingProposal(){
        let proposal = await fetch('https://clayapi.com/baking/proposal/').then(response => response.json())
                                                                  .then(data => data)

      return proposal
    }



async function getParameters(){
  const protocolParams = await fetch("https://clayapi.com/baking/parameters/")
  const json_params = await protocolParams.json()
  return json_params
}

async function getCurrentSlot(){
    let slot = await fetch('https://clayapi.com/baking/slot/').then(response => response.json())
                                                                  .then(data => data)

    slot = parseInt(slot.slot)

    return slot
}


async function getUtxos(cardano){
      const txUnspentOutputs = serLib.TransactionUnspentOutputs.new()
        const utxos = (await cardano.getUtxos()).map((utxo) =>
            txUnspentOutputs.add(serLib.TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex")))
         );
        return txUnspentOutputs
}


function isBalanceSufficient(assets_to_bake, assets_found) {
  if (assets_to_bake.length === assets_found.length) {
    return assets_to_bake.every(element => {
      if (assets_found.includes(element)) {
        return true;
      }

      return false;
    });
  }
  return false;
}


function get_utxo_hashes_for_baking(assets_to_bake, utxos){
    let required_for_baking = []

    let required_for_baking_check = []

    //console.log(assets_to_bake)

    //console.log(utxos)

    let utxo_list = display_utxos(utxos)

    //console.log(utxo_list)

    for (let i = 0; i < utxo_list.length; i++){
        let cur_utxo = utxo_list[i]
        let cur_amount = utxo_list[i]['amount']
        
        for (let j = 0; j < cur_amount.length; j++){
            
            //let utxo = {'tx_hash':cur_utxo['tx_hash'],'tx_index':String(cur_utxo['tx_index'])}

            let utxo = cur_utxo['tx_hash'] + '#' + String(cur_utxo['tx_index'])

            if (assets_to_bake.includes(cur_amount[j]['unit'])){
               required_for_baking_check.push(cur_amount[j]['unit'])

                if (!required_for_baking.includes(utxo)){
                    required_for_baking.push(utxo)
                    
                }
            }
        }
    }

    //console.log("CHECKING SUFFICIENT BALANCE")

    //console.log(assets_to_bake)
    //console.log(required_for_baking_check)

    let isSufficient = isBalanceSufficient(assets_to_bake, required_for_baking_check)

    //console.log(isSufficient)

    if (isSufficient){
        return required_for_baking
    }else{
      throw 'Balance of assets is not SUFFICIENT!'
    }
}


function get_required_signers(utxos){
    let required_keys = []

    for (let i = 0; i < utxos.length; i ++){
        let utxo = utxos[i]
        let utxo_output_address = utxo.output().address()
        //console.log(utxo_output_address.to_bech32())

        let utxo_key_hash = Buffer.from(serLib.BaseAddress.from_address(utxo_output_address).payment_cred().to_keyhash().to_bytes()).toString('hex')

        if (!required_keys.includes(utxo_key_hash)){
            required_keys.push(utxo_key_hash)
        }

    }
    return required_keys
}


function get_required_utxos_for_baking(baking_utxo_hashes, utxos){
    let required_utxos = []
    for (let i = 0; i < utxos.len(); i ++){
        let utxo = utxos.get(i)
        let utxo_input = utxo.input()
        let utxo_output = utxo.output()

        let utxo_amount = utxo_output.amount()

        let utxo_lovelace = utxo_amount.coin().to_str()

        let utxo_multiasset = utxo_amount.multiasset()

        let tx_hash = Buffer.from(utxo_input.transaction_id().to_bytes()).toString('hex')
        let output_index = utxo_input.index()

        let utxo_hash = tx_hash + '#' +String(output_index)

        //console.log('UTXO HASH')

        //console.log(utxo_hash)

        if (baking_utxo_hashes.includes(utxo_hash)){
           //console.log('HEY')
            //console.log(utxo_hash)

            required_utxos.push(utxo)
        }
    }

    return required_utxos
}


function compare_inputs(input_1, input_2){
    let tx_hash_1 = Buffer.from(input_1.transaction_id().to_bytes()).toString('hex')
    let tx_index_1 = input_1.index()

    let tx_hash_2 = Buffer.from(input_2.transaction_id().to_bytes()).toString('hex')
    let tx_index_2 = input_2.index()

    //console.log(tx_hash_1 + '#' + tx_index_1)
    //console.log(tx_hash_2 + '#' + tx_index_2)

    return (tx_hash_1 == tx_hash_2 && tx_index_1 == tx_index_2)
}

function parse_inputs(utxo_inputs){
    let inputs_list = []
    for (let i = 0; i < utxo_inputs.len(); i++){
        let utxo_input = utxo_inputs.get(i)
        let tx_hash = Buffer.from(utxo_input.transaction_id().to_bytes()).toString('hex')
        let tx_index = String(utxo_input.index())

        inputs_list.push({'tx_hash':tx_hash,'tx_index':tx_index})
    }
    return inputs_list
}


function display_utxos(utxos){
    let utxo_list = []
    for (let i = 0; i < utxos.len(); i++){
        let utxo = utxos.get(i)

        let utxo_input = utxo.input()
        let utxo_output = utxo.output()

        let utxo_amount = utxo_output.amount()

        let utxo_lovelace = utxo_amount.coin().to_str()

        let utxo_multiasset = utxo_amount.multiasset()

        let cur_utxo = []

        cur_utxo.push({"unit":"lovelace","quantity":utxo_lovelace})

        if (typeof(utxo_multiasset) != 'undefined'){
           
            for (let j = 0; j < utxo_multiasset.len(); j++){
                let policy_script = utxo_multiasset.keys().get(j) 
                let assets = utxo_multiasset.get(policy_script)

                for (let k = 0; k < assets.len(); k++){
                    let asset_amount = assets.get(assets.keys().get(k))
                    //console.log('ASSET NAME')
                    //console.log(assets.keys().get(k))

                    let asset_name = Buffer.from(assets.keys().get(k).name()).toString('hex')

                    //console.log(asset_name)

                    let policy_hash = Buffer.from(policy_script.to_bytes()).toString('hex')

                    let asset_id = policy_hash + asset_name

                    cur_utxo.push({"unit":asset_id,"quantity":asset_amount.to_str()})

                    //console.log(cur_utxo)
                }
            }
        }

        let tx_hash = Buffer.from(utxo_input.transaction_id().to_bytes()).toString('hex')
        let output_index = utxo_input.index()
        utxo_list.push({"tx_hash":tx_hash,"tx_index":String(output_index), "amount":cur_utxo})
    }

    return utxo_list
}



async function get_txBuilder(protocolParams){
  
   //console.log(protocolParams)

   const min_fee_a = String(protocolParams.min_fee_a)
   const min_fee_b = String(protocolParams.min_fee_b)
   const pool_deposit = String(protocolParams.pool_deposit)
   const key_deposit = String(protocolParams.key_deposit)
   const max_value_size = protocolParams.max_val_size
   const max_tx_size = protocolParams.max_tx_size
   const coins_per_utxo_word = String(protocolParams.coins_per_utxo_word)

   const linearFee = serLib.LinearFee.new(
       serLib.BigNum.from_str(min_fee_a),
       serLib.BigNum.from_str(min_fee_b)
   );


   const txBuilderCfg = serLib.TransactionBuilderConfigBuilder.new()
       .fee_algo(linearFee)
       .pool_deposit(serLib.BigNum.from_str(pool_deposit))
       .key_deposit(serLib.BigNum.from_str(key_deposit))
       .max_value_size(max_value_size)
       .max_tx_size(max_tx_size)
       .coins_per_utxo_word(serLib.BigNum.from_str(coins_per_utxo_word))
       .prefer_pure_change(false)
       .build();

   const txBuilder = serLib.TransactionBuilder.new(txBuilderCfg);
   return txBuilder
}


async function getAssetRewards(asset, asset_info, slot){
    let slot_difference = parseInt(slot) - parseInt(asset_info.slot) - 1209600
    let per_slot_rate = asset_info.rate / 86400

    let rewards = per_slot_rate * slot_difference

    rewards = rewards.toFixed(0)

    return rewards
}

async function getTotalRewards(assets, assets_info, slot){
  let total = 0 

  for (let i = 0; i < assets.length; i++){
      let asset_rewards = await getAssetRewards(assets[i], assets_info[assets[i]],slot)
      total += parseInt(asset_rewards)
  }

  return total
}

function get_asset_ids_for_baking(assets){

  let assets_to_bake = []
  for (let i = 0; i < assets.length; i++){
    let cur_asset = assets[i]
    let cur_collection = cur_asset.slice(0,2)
    let cur_serial = cur_asset.slice(2,)

    let cur_policy_id = eligible_info[cur_collection]['policy_id']
    let cur_base_name = eligible_info[cur_collection]['base_name']

    let cur_name = cur_base_name + String(cur_serial)

    cur_name = Buffer.from(cur_name).toString('hex')

    let cur_asset_id = cur_policy_id + cur_name

    assets_to_bake.push(cur_asset_id)

  }

  return assets_to_bake
}


async function get_assets_info(){
    if (stake_address){
        let assets_info = await fetch('https://clayapi.com/baking/wallet/' + stake_address + '/')
            .then(response => response.json())
            .then(data => data);
        return assets_info.assets
    }else{
        return {}
    }
}


async function InitiateBaking(){
      //console.log('Step 1: Voting...')
      //console.log(selectedTokens)

      //TEST
      /*
      const proposal = await getVotingProposal()
      setVoteProposal(proposal)
      setIsVoteOpen(true)
      */


      if (selectedTokens.length > 0){
          const proposal = await getVotingProposal()
          setVoteProposal(proposal)
          setIsVoteOpen(true)
      }else{
          notifyWarn('No assets selected for baking')
        }
    }

function checkContainsSufficient(all_assets, required_assets){
    for (let i = 0; i < required_assets.length; i++){
        if (!all_assets.includes(required_assets[i])){
            return false
        }
    }
    return true
}


async function getBakingCredentials(assets, assets_info){
  //this function returns a hash 
  const credLabel = "3101"
  //console.log(assets)

  let aux_data = serLib.AuxiliaryData.new()

  //aux_data.set_metadata()
  let genTxMeta = serLib.GeneralTransactionMetadata.new()

  let metadataList = serLib.MetadataList.new()
  for (let i = 0; i < assets.length; i++){
    let latestBakeTx = assets_info[assets[i]].tx_id

    console.log(latestBakeTx)
    metadataList.add(serLib.TransactionMetadatum.new_text(latestBakeTx))
  }

  genTxMeta.insert(serLib.BigNum.from_str(credLabel),serLib.TransactionMetadatum.new_list(metadataList))

  aux_data.set_metadata(genTxMeta)

  //console.log(serLib.hash_auxiliary_data(aux_data))
  //console.log(Buffer.from(serLib.hash_auxiliary_data(aux_data).to_bytes()).toString('hex'))


  return Buffer.from(serLib.hash_auxiliary_data(aux_data).to_bytes()).toString('hex')

}

async function submitTransaction(transaction, witness){
    const data = {'tx':transaction,'witness':witness}
    const response = await fetch('https://clayapi.com/baking/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()
}



async function setInitialFunction(protocolParams, receiver_encoded){

  let txBuilder_1 = await get_txBuilder(protocolParams)

  const lovelaceToSend = 2500000

  txBuilder_1.add_output(
      serLib.TransactionOutput.new(
          receiver_encoded,
          serLib.Value.new(serLib.BigNum.from_str(String(lovelaceToSend+500000)))
      ),
  );




  return txBuilder_1


}

async function BuildBakingTransaction(assets, proposal){

    //toast.update(id, { render: "All is good", type: "success" });

    await new Promise(r => setTimeout(r, 950));

    if (hasBaking || flamesVisible){
        notifyInfo('Oven is ready!')
    }else{
        notifyInfo('Heating up the oven...')
    }
    
    setKilnAnimation('flames 2s linear infinite alternate-reverse both 0.2s')
    setFlamesVisible(true)

    await new Promise(r => setTimeout(r, 950));
    notifyInfo('Building transaction...')
    await new Promise(r => setTimeout(r, 1450));



    const asset_name = "BakeClay"
    const policy_signer_keyhash = "e94ba89183b611d3aed14e818c8421a5286608f22ff41e27330e3985"
    let receiver = "addr1z8tlml0x8spf2qyzse7qwh0hh4qp50fhx6m2kmr3wsvez340fm4l4jflstl7hz7ucs5793gzr297g67psdx8dssdf68ssh59m2"
    let receiver_encoded = serLib.Address.from_bech32(receiver)
    const policyScriptHash = "8201828200581ce94ba89183b611d3aed14e818c8421a5286608f22ff41e27330e398582051a0f4beeca"







    /*
    const asset_name = "TestBake"

    const policy_signer_keyhash = "d1cc41520fde1060908ce5ce94fa67cb250e981aa249672c7814a240"

    let receiver = 'addr1z9x57f8sd6wz37nm2cdkfzmfhcgjs98zpl5dul5js80gwsqrxpgqxqan3e2nnldfpmmw2xtvrahmt9n4tv2yqns20mpqfq6uhu'
    let receiver_encoded = serLib.Address.from_bech32(receiver)

    const policyScriptHash = "8201828200581cd1cc41520fde1060908ce5ce94fa67cb250e981aa249672c7814a24082051a0f4beeca"
    */
    


    /*
    const asset_name = "TestTest"

    //const policy_signer_pubkey = '38172722909f74d4d635986a455f2e1be40c558f7573afdd28e0323f5c54c61a'
    const policy_signer_keyhash = 'a77321f27ae07c6dcc9429b0e10eafe553ea6eec439251937318228c'

    let receiver = 'addr1q8glw646ecx5z8avaj3nfq83fsrdpe63pf9ac82wx92k87rc5fszvja3xdn34lgyfwfanzxup8dsgm0yljwy2m5e0y8qkl0kh2'
    let receiver_encoded = serLib.Address.from_bech32(receiver)

    const policyScriptHash = "8201828200581ca77321f27ae07c6dcc9429b0e10eafe553ea6eec439251937318228c82051a047868c0"
    */



    const policyScript = serLib.NativeScript.from_bytes(Buffer.from(policyScriptHash,'hex'))
    const policyId = Buffer.from(
          serLib.ScriptHash.from_bytes(
                policyScript.hash().to_bytes()
            ).to_bytes(),
            "hex"
        ).toString("hex");

    //console.log('policyId')
   //console.log(policyId)







   const currentSlot = await getCurrentSlot()

   //console.log('CURRENT SLOT')
   //console.log(currentSlot)

  const assets_to_bake = get_asset_ids_for_baking(assets)

  const assets_to_bake_info = await get_assets_info()

  if(!checkContainsSufficient(Object.keys(assets_to_bake_info), assets_to_bake)){
     notifyError('Could not fetch required assets')
     return
  }
 

  //console.log(assets_to_bake)

  let cardano = await window.cardano[activeWallet]
  cardano = await cardano.enable()

 

  let address_encoded = await cardano.getUsedAddresses()

  address_encoded = address_encoded[0]

  address_encoded = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex"))



   const proposal_id = proposal.proposal_id

   const VOTE = String(proposal.proposal_id) + ':' + String(Object.keys(proposal.option)[0])

   //console.log(VOTE)

   //const asset_name = "TestTest"



   //add credential hash H:[''] 

   const bakingCredential = await getBakingCredentials(assets_to_bake, assets_to_bake_info)

   const metadata = {"C":assets,"V":[VOTE],"H":[bakingCredential]}
   //console.log(`METADATA: ${JSON.stringify(metadata, null, 4)}`);

   const accumulated_rewards = await getTotalRewards(assets_to_bake, assets_to_bake_info, currentSlot)


   //console.log(accumulated_rewards)




  //PROTOCOL PARAMS
  let protocolParams = await getParameters()


  const utxos = await getUtxos(cardano)

  //console.log(utxos)


  let txBuilder = await get_txBuilder(protocolParams)

  const lovelaceToSend = 2500000

  txBuilder.add_output(
      serLib.TransactionOutput.new(
          receiver_encoded,
          serLib.Value.new(serLib.BigNum.from_str(String(lovelaceToSend+2000000)))
      ),
  );

  const ttl = parseInt(currentSlot) + 7200


  //txBuilder.add_inputs_from(utxos, 1)
  //txBuilder.add_change_if_needed(address_encoded)




  let attempts = 0
  let isSuccessful = false
   while (true){
      try{
          //console.log('I AM HERE')

        
         let test_inputs;

         let currentError = ''


    try{

        let txBuilder_2 = await setInitialFunction(protocolParams, address_encoded)

        txBuilder_2.add_inputs_from(utxos, 1)

        txBuilder_2.add_change_if_needed(address_encoded)

        test_inputs = txBuilder_2.build_tx().body().inputs()
    }catch (error){
        //console.log('\n\n\nCAUGHT 1\n\n\n')
        currentError = error
    }


    try{

        if (!test_inputs){

            let txBuilder_3 = await setInitialFunction(protocolParams, address_encoded)

            txBuilder_3.add_inputs_from(utxos, 0)

            txBuilder_3.add_change_if_needed(address_encoded)

            test_inputs = txBuilder_3.build_tx().body().inputs()
        }
    }catch (error){
       // console.log('\n\n\nCAUGHT 2\n\n\n')
        currentError = error
    }


        try{

        if (!test_inputs){

            let txBuilder_4 = await setInitialFunction(protocolParams, address_encoded)

            txBuilder_4.add_inputs_from(utxos, 3)

            txBuilder_4.add_change_if_needed(address_encoded)

            test_inputs = txBuilder_4.build_tx().body().inputs()
        }
    }catch (error){
        //console.log('\n\n\nCAUGHT 3\n\n\n')
        currentError = error
    }



    try{

        if (!test_inputs){

            let txBuilder_5 = await setInitialFunction(protocolParams, address_encoded)

            txBuilder_5.add_inputs_from(utxos, 2)

            txBuilder_5.add_change_if_needed(address_encoded)

            test_inputs = txBuilder_5.build_tx().body().inputs()
        }
    }catch (error){
        //console.log('\n\n\nCAUGHT 4\n\n\n')
        currentError = error
    }


    if (!test_inputs){
        throw currentError
    }
 

       // console.log(test_inputs)

        let utxo_hashes;
        try{
          utxo_hashes = get_utxo_hashes_for_baking(assets_to_bake, utxos)
        }catch (error){
          notifyError(error)
          break
        }
        let required_utxos = get_required_utxos_for_baking(utxo_hashes, utxos)

        /*
        console.log(utxo_hashes)
        console.log(assets_to_bake)
        console.log(required_utxos)
        */

        let transaction_inputs = []



        for (let i = 0; i < test_inputs.len(); i++){
                for (let j = 0; j < utxos.len(); j ++){

                    /*
                    console.log('UTXOS')
                    console.log(utxos.get(j))
                    console.log(utxos.get(j).input())
                    console.log('COMPARE')
                    */


                    if (compare_inputs(utxos.get(j).input(),test_inputs.get(i))){
                        //console.log(utxos.get(j))
                        transaction_inputs.push(utxos.get(j))
                    }
                }
            }


            //console.log(transaction_inputs)
            //console.log(required_utxos)

            let new_inputs = []

            //console.log('NEW INPUTS')
            //console.log(new_inputs)

            
            let isEnoughLovelace = false
            for (let i = 0; i < required_utxos.length; i++){
                //console.log(required_utxos[i].input())

                new_inputs.push(required_utxos[i])

                if (parseInt(required_utxos[i].output().amount().coin().to_str()) > 6000000){
                    isEnoughLovelace = true
                }
            }


            //if (!isEnoughLovelace){
                for (let i = 0; i < transaction_inputs.length; i++){
                    let isIncluded = false 

                    for (let j = 0; j < new_inputs.length; j++){
                        let cur_input1 = Buffer.from(new_inputs[j].input().transaction_id().to_bytes()).toString('hex')+'#'+String(new_inputs[j].input().index())
                        let cur_input2 = Buffer.from(transaction_inputs[i].input().transaction_id().to_bytes()).toString('hex')+'#'+String(transaction_inputs[i].input().index())

                        //console.log(cur_input1)
                        //console.log(cur_input2)

                        if (cur_input1 == cur_input2){
                            isIncluded = true
                        }
                    }

                    if (!isIncluded){
                        new_inputs.push(transaction_inputs[i])
                    }
                }

           // }

           //console.log('New Inputs')
           //console.log(new_inputs)






            //console.log('NEW INPUTS 2')
            //console.log(required_utxos.length)
            //console.log(new_inputs)


            //we also need to find required signers
            let required_signers = get_required_signers(new_inputs)
            required_signers.push(policy_signer_keyhash)


            let txInputs = serLib.TxInputsBuilder.new()


            for (let i = 0; i < new_inputs.length; i++){
                //console.log(new_inputs[i])

                //console.log(new_inputs[i].output().amount())

                txInputs.add_input(address_encoded,new_inputs[i].input(), new_inputs[i].output().amount())
            }


            txBuilder = await get_txBuilder(protocolParams)


            txBuilder.add_mint_asset_and_output(
                  policyScript,
                  serLib.AssetName.new(Buffer.from(asset_name)),
                  serLib.Int.new_i32(accumulated_rewards),
                  serLib.TransactionOutputBuilder.new().with_address(receiver_encoded).next(),
                  serLib.BigNum.from_str(String(lovelaceToSend))
                  );

                txBuilder.add_json_metadatum(
                serLib.BigNum.from_str("4159"),
                JSON.stringify(metadata)
               );


            txBuilder.set_inputs(txInputs)
            txBuilder.set_ttl_bignum(serLib.BigNum.from_str(String(ttl)));
                        

            //ADDING REQUIRED SIGNERS
            for (let i = 0; i < required_signers.length; i++){
               txBuilder.add_required_signer(serLib.Ed25519KeyHash.from_bytes(Buffer.from(required_signers[i],'hex')))
            }



            txBuilder.add_change_if_needed(address_encoded)







            //CHECK OUTPUTS
            /*
            let includedOutputs = txBuilder.get_explicit_output()

            console.log(includedOutputs.coin().to_str())
            console.log(includedOutputs.multiasset())
            console.log(includedOutputs.multiasset().keys())
            
           
            for (let k = 0; k < includedOutputs.multiasset().keys().len(); k++){
                let cur_scripthash = includedOutputs.multiasset().keys().get(k)

                console.log(includedOutputs.multiasset().get(cur_scripthash))


                let cur_assets = includedOutputs.multiasset().get(cur_scripthash)

                for (let h = 0; h < cur_assets.keys().len(); h++){
                    console.log('asset key')
                    console.log(cur_assets.keys().get(h))

                    console.log(Buffer.from(cur_assets.keys().get(h).name()).toString('utf-8'))
                    

                    console.log('asset quantity ?')

                    console.log(cur_assets.get(cur_assets.keys().get(h)))

                }
            }
           */







            //console.log(txBuilder.get_fee_if_set().to_str())


            isSuccessful = true
            break


      }catch (error){
         //console.log(error)
            //console.log('Attempts ' + attempts)
            if (attempts < 10){
                attempts += 1
            }else{
                break;
            }
        }     
    }
  

   if (isSuccessful){
        try{
         const unsignedTx = txBuilder.build_tx()
         const unsignedTx_hash = Buffer.from(unsignedTx.to_bytes()).toString('hex')
         let tx_witness = await cardano.signTx(unsignedTx_hash,true)


         
         console.log('TO VALIDATE!!!')
         console.log(unsignedTx_hash)
         console.log(tx_witness)
        
        
         //TO GET TRANSACTION ID
         const txBody = unsignedTx.body()
         const txBodyHash = serLib.hash_transaction(txBody)
         const txId = Buffer.from(txBodyHash.to_bytes()).toString('hex');
         //console.log(txId)
         

         const baking_status = await submitTransaction(unsignedTx_hash, tx_witness)
         

         //console.log(baking_status)

         if ('error' in baking_status){
            permError(baking_status['error'])
         }else if ('TXID' in baking_status){
            //permSuccess('Transaction has been submitted!')/*+ baking_status['TXID'])*/

            setIsAlertOpen(true)
            setSubmittedTx(baking_status['TXID'])

            setSelectedTokens([])
         }else{
            permError('Transaction failed')
            setFlamesVisible(false)
         }

     }catch (error){
        notifyError(error.info)
        setFlamesVisible(false)
     }
      }else{
        notifyError('Failed to initiate baking!')
        setFlamesVisible(false)
      }
}


useEffect(() => {
    if (voteOption != null && typeof(voteOption) != 'undefined'){
     //console.log(voteOption)
     let final_voteOption = voteOption
     let final_selectedTokens = selectedTokens
     BuildBakingTransaction(final_selectedTokens, final_voteOption)
     setVoteOption()
    }
}, [voteOption])


  return (
    <div>
      <button id="claimButton" className="claimBtn" onClick={(event)=>{
        InitiateBaking()}}>
          <ButtonText>BAKE</ButtonText>
      </button>
    </div>

  );
}

export default ClaimClay;
