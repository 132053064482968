import { useState, useContext, useEffect } from 'react'
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';


import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import styled,  { keyframes } from 'styled-components';

import Avatar from '@mui/material/Avatar';

import ButtonBase from '@mui/material/ButtonBase';

import Stack from '@mui/material/Stack';

import {MainClaimContext} from './ClaimContext'

import './FluidProgress.css'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const theme = createTheme({
  typography: {
    fontFamily: [
      'shortstack',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});



function ClayCard(props) {

  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)

  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)

  const setHasBaking = props.setHasBaking

  const clay_id = props.clay_id

  const startColor = props.startColor

  const [daysBaked, setDaysBaked] = useState('???')

  const [curAmount, setCurAmount] = useState('????')

  const [cardLoaded, setCardLoaded] = useState(false)

  const [cardHover, setCardHover] = useState(false) 

  const [isClicked, setIsClicked] = useState(false)

  const [isBaking, setIsBaking] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const [startPercent, setStartPercent] = useState(0)
  const [endPercent, setEndPercent] = useState(0)

  const [anim, setAnim] = useState(null)

  const slot = props.slot
  
  const image = props.image
  const serial = props.serial
  const amount = props.amount
  const borderColor = props.borderColor
  const serialColor = props.serialColor

  const clayInfo = props.clayInfo
  const currentSlot = props.currentSlot

  const selectedAssets = props.selectedAssets
  const setSelectedAssets = props.setSelectedAssets

  const collection = props.collection

  const progress = keyframes`
    from {
      width: 5%; background-color: rgba(127, 212, 239, 1);
    }

    to {
      width: 100%; background-color: #0099EF;
    }
  `

  let actionArea = {
     width: '225px',
     height: '325px',
     borderRadius: '16px',
  }


let cardStyle = {

    width: '220px',
    height:'320px',
    background:`linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.2) 75%), radial-gradient(88.18% 98.18% at 50% 138.42%, rgba(17, 19, 35, 0.27) 99.99%, rgba(0, 0, 0, 0) 100%)` ,
    borderRadius: '16px',
    boxShadow: cardHover ? '0 0px 28px 0' + borderColor : '0 0px 16px 0 rgba(225, 224, 214, 0.7)',
    overflow: 'hidden',
    backdropFilter: 'blur(58px)',
    border: '3px solid' + borderColor,
  }


let selectedCardStyle = {
    width: '220px',
    height:'320px',
    background: collection == 'cn' ? 'rgba(255, 156, 131,0.3)' : 'rgba(83, 201, 238,0.4)',
    backgroundImage:`linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.2) 75%), radial-gradient(88.18% 98.18% at 50% 138.42%, rgba(17, 19, 35, 0.27) 99.99%, rgba(0, 0, 0, 0) 100%)` ,
    borderRadius: '16px',
    boxShadow: cardHover ? '0 0px 28px 0' + borderColor : '0 0px 16px 0'+ borderColor,
    overflow: 'hidden',
    backdropFilter: 'blur(58px)',
    border: '3px solid' + borderColor,
}

 
 const displaySerial = (temp) => {
    if (temp < 10){
      return '#000'+String(temp)
    }else if (temp < 100){
      return '#00'+String(temp)
    }else if (temp < 1000){
      return '#0'+String(temp)
    }else{
      return '#'+String(temp)
    }
 }
 
 const displayAmount = () => {
  if (clayInfo && currentSlot){
    if (clayInfo.processing_tx == null){
        let rate_per_slot = clayInfo.rate / 86400
        let slots_passed = parseInt(currentSlot) - parseInt(clayInfo.slot) - 1209600

        let total = slots_passed * rate_per_slot
        total = total.toFixed(0)
        setCurAmount(total)

       
        if (clay_id in selectedTokens){
          if (total <= 0){
            cardClick()
            total = 0
            }
        }
        

        return total
    }else{
      setIsProcessing(true)
      setCurAmount('Processing...')

      if (clay_id in selectedTokens){
        cardClick()
      }

      return 'Processing...'
    }

  }else{
    setCurAmount('????')
    return '????'
  }
 }

 const cardClick = () => {
   if (isBaking){
    return
   }
  if (isProcessing){
    return
   }
     let new_selectedAssets;
     if (typeof(selectedTokens) == 'undefined'){
        new_selectedAssets = []
     }else{
        new_selectedAssets = [...selectedTokens]
     }
      if (new_selectedAssets.includes(clay_id)){
        let index_to_remove = new_selectedAssets.indexOf(clay_id)
        new_selectedAssets.splice(index_to_remove, 1)
        setIsClicked(false)
      }else{

        if (selectedTokens.length < 20){

          new_selectedAssets = [...selectedTokens, clay_id]
          setIsClicked(true)
        }else{
          notifyWarn('Max 20 assets allowed!')
          return
        }
      }
      setSelectedTokens(new_selectedAssets)
 }


 function getAssetStatus(){

    //let days = Math.floor((current_slot - clay.slot) / 1209600)

 }

 useEffect(() => {

  //console.log('RECALCULATING')

  displayAmount()

  /*
  console.log(clayInfo)
  console.log(clayInfo.processing_tx == null)
  console.log('SLOT')
  console.log(slot)
  console.log(clayInfo['slot'])

  console.log(collection)
  console.log(serial)
  console.log(typeof(collection))
  */



  if ( (slot - clayInfo.slot) < 1209600 && clayInfo.processing_tx == null){
      setIsBaking(true)

      setHasBaking(true)

      let lower = String(Math.floor( (slot - clayInfo.slot) / 86400))
      //console.log(lower)

      if (lower < 1){
        setAnim(collection + '0 6s infinite')
        setDaysBaked('0 days')
      }else if (lower >= 1 && lower < 2){
        setDaysBaked('1 day')
        setAnim(collection + '1 6s infinite')
      }else{
        setAnim(collection + String(lower) + ' 6s infinite')
        setDaysBaked(String(lower) + ' days')
      }

      /*
      setStartPercent(String((Math.floor( (slot - clayInfo.slot) / 86400) / 14 * 100).toFixed(2)))
      let upper = (Math.ceil( (slot - clayInfo.slot) / 86400) / 14 * 100).toFixed(2)

      if (upper < 7){
        setEndPercent(String(7))
      }else{
        upper = (Math.ceil( (slot - clayInfo.slot) / 86400) / 14 * 100).toFixed(2)
        setEndPercent(String(Math.min(upper, 100)))
      }
      */

  }else{
      setIsBaking(false)
  }

 },[slot])


  return (
   <ThemeProvider theme={theme}>
    <div>
    <CardActionArea style={actionArea} onMouseOver={()=>{setCardHover(true)}} onMouseOut={()=>{setCardHover(false)}} onClick={() => cardClick()}>
      <Card style = {selectedTokens.includes(clay_id) ? selectedCardStyle : cardStyle} >
        <CardContent>
          <div style={{ display:'flex', justifyContent:'center' }}>

          {!cardLoaded ? <Skeleton variant='circular'> <Avatar sx={{ width: 180, height: 180 }} />  </Skeleton> : null}

          <Avatar
          alt="Clay Image"
          src={image}
          sx={{ width: 180, height: 180 }}
          style={cardLoaded ? {} : { display: 'none' }}
          onLoad={()=>{setCardLoaded(true)}}
           />

          </div>
          <div style={{ display:'flex', justifyContent:'center' }}>

          {isBaking ? 

        <List style={{width:"180px", paddingTop:"0px",paddingBottom:"0px",marginTop:"20px",backgroundColor:'rgba(66, 70, 89,0.9)', backdropFilter:'blur(58px)'}}>
          <Typography  variant={'h5'} color={serialColor}>
            {displaySerial(serial)}
          </Typography>

          <div className="container">
            <div className="progress2">

            {/*<div style={{marginBottom:'-16px'}}></div>*/}
             <div className="progress-fluid">


             {collection == 'cn' ? 
                  <div className="progress-bar"  style={{animation: anim}}></div>
                  : 
                   <div className="progress-bar" style={{animation: anim}}></div>
                  }
                </div>        
             </div>

           <div className="Baking"><h1>Baking</h1></div>
            <div className="Days"><h1>{daysBaked}</h1></div>
          </div>

         </List>

            : 

        <Stack style={{width:"180px", paddingTop:"0px",paddingBottom:"0px",marginTop:"20px", backgroundColor:'rgba(66, 70, 89,0.9)', backdropFilter:'blur(58px)'}}>
          <Typography  variant={'h5'} color={serialColor} style={{fontFamily:'gloriahallelujah'}}>
            {displaySerial(serial)}
          </Typography>

        <div style={{ display:'flex', alignItems:'flex-end', justifyContent:'center', height: '45px', marginBottom:'5px'}}>
          
        {curAmount != 'Processing...' ?
          <Avatar
            alt="Clay Image"
            src='/CLAY_TOKEN_220x220.png'
            sx={{ width: 22, height: 22, marginBottom: '5px', marginRight: '3px' }}
             />
            : null}

          <Typography  variant={'h5'} color="#009ef7">
              {curAmount}
          </Typography>
          </div> 
          </Stack>
        }

         
          </div>
        </CardContent>
      </Card>
    </CardActionArea>
    </div>
     </ThemeProvider>
  )
}

export default ClayCard