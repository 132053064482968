import { MainPannel }  from "./MainGrid.styled";
import { motion } from "framer-motion";
import argie from "../Images/argie.gif";
import { GIF, H1, MainContainer } from "./NoAssets.style.jsx";

import empty from '../Images/Empty.png'

function NoAssets () {

    return (


        <MainContainer as={motion.div}
        initial={{ x:0, opacity:0 }} 
        whileInView="visible"
        viewport={{ once: true }}
        animate={{ opacity:1, x:0, type: "spring" }}
        transition={{ duration: 1.2, delay: 1.5}}
        >
            {/*<GIF src={argie} />*/}

            <GIF src={empty} />

            <H1 style={{fontFamily:'Inter'}}>NO ASSETS TO LOCK</H1>
        </MainContainer>


    );



}

export default NoAssets;

