import { useState, useEffect, useContext } from 'react'


import Stack from '@mui/material/Stack';


import styled from "styled-components";
import { motion } from "framer-motion";


const MarketContainer = styled(motion.div)`
display: flex;
flex-direction: column;
align-items: center;
border-radius: 1rem;
border: 6px solid rgba(0, 0, 0, 0.17);
padding: 1rem 2rem;
max-width: 60%;
position: relative;
text-align: center;
justify-content: center;
line-height: 1;
margin-top: 2rem;
background-color: var(--panel-background);
`;


const NumberContainer = styled.div`
background-color: rgba(0, 0, 0, 0.4);
text-align: center;
align-items: center;
justify-content: center;
gap: 2rem;
color: ${(props) => (themes[props.theme] ? themes[props.theme].color : 'inherit')};
font-size: 18px;
padding: 12px 18px;
border-radius: 1.4rem;
`;



const H1 =  styled.h1 `
font-size: 24px;
color: var(--claytext-primary);
font-family: 'ClayNationFont';
white-space: nowrap;
font-weight: 100;
letter-spacing: 0.2rem;
text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.4);
`;

const themes = {
    pink: {
        default: 'var(--claypink)',
        color: 'var(--claypink)',
    },
    blue: {
        default: 'var(--clayblue-primary)',
        color: 'var(--clayblue-primary)',
    }, 
    green: {
        default: 'var(--claygreen)',
        color: 'var(--claygreen)',
    }

};

const H2 =  styled.h1 `
font-size: 16px;
color: ${(props) => (themes[props.theme] ? themes[props.theme].color : 'inherit')};
font-family: 'ClayFontJika';
white-space: nowrap;
font-weight: 100;
letter-spacing: 0.2rem;
text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.4);
`;


function MarketOverview(props) {


  const [marketInfo, setMarketInfo] = useState({})


useEffect(()=>{
    get_market_overview()
}, [])


    
async function get_market_overview(){

    let query_url = 'https://claymarket.io/api/market/overview/' 

    let market_overview = await fetch(query_url).then(response => response.json())
                                     .then(data => data)


    if ('error' in market_overview){
      return 
    }
                                     
    setMarketInfo(market_overview) 

    //console.log(market_overview) 


}


function displayAmount(amount, precision){
    if (amount < 10000){
      return String(amount)
    }else if (amount < 1000000){
      return String((amount / 1000).toFixed(precision)) + 'K'
    }else if (amount < 1000000000){
      return String((amount / 1000000).toFixed(precision)) + 'M'
    }
  }







return (

 <div style={{display:'flex', flexDirection:'column', justifyContent:'start', alignItems:'center', height:'200px', marginTop:'0px'}}>

     <MarketContainer 
        initial={{ opacity: 0, scale: 0.5 }} 
        animate={{ opacity: 1, scale: 1 }} 
        transition={{ duration: 1, delay: 0.5 }}
    >

            <H1>MARKET OVERVIEW</H1>
            <NumberContainer theme="green">{marketInfo && 'total_assets_listed' in marketInfo ? displayAmount(parseInt(marketInfo['total_assets_listed'],10), 0) : '-'}</NumberContainer>
            <H2 theme="green">TOTAL ASSETS LISTED</H2>
            <NumberContainer theme="blue">{marketInfo && 'total_unique_wallets' in marketInfo ? displayAmount(parseInt(marketInfo['total_unique_wallets'],10), 1) : '-'}</NumberContainer>
            <H2 theme="blue">TOTAL UNIQUE WALLETS</H2>
                        
        </MarketContainer>
    </div>

  )
}

export default MarketOverview




