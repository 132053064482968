import { useState, useEffect, useContext } from 'react'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';


import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

import BoltIcon from '@mui/icons-material/Bolt';

import './CollectionCard.css'

import './Raffles.css'

import {MainClaimContext} from './ClaimContext'

import RaffleButton from './RaffleButton'


import styled,  { keyframes } from 'styled-components';

const Card = styled.div`
    position: absolute;
    text-align: center;
    border-radius: 10px;
    width: 272px;
    height: 384px;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    color: black;
    display: block;
    background-color: transparent;
    text-align: center;

    &:hover {
      box-shadow: 0 0px 28px ${props => props.boxShadow || "#FF9C83"};
      cursor: pointer;
    }
  `

const CardText = styled.div`
    margin-top: 2px;
    position: relative;
    font-family: ShortStack;
    font-size: 1.2rem;
    color: ${props => props.color || "#FF9C83"};
    opacity: 0.9;
`


 const cardCollections = {
    "cn":"40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728",
    "gc":"94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768",
    "pitches":"13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e"
  }



  const ButtonText = styled.h1`
    position: relative;
    font-size: 15px;
    font-family: 'shortstack';
    letter-spacing: 0.1em;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    color: #fff;
    z-index: 4;
  `





export function CollectionCard(props){

  const {address, stake_address} = useContext(MainClaimContext)

  const raffleAsset = props.raffleAsset
  const setRaffleAsset = props.setRaffleAsset


  const setFeaturedRaffle = props.setFeaturedRaffle

  const cn_image_url = "https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/" + "QmfKc4QcXrriUiHhao9TFqvWx8G6iitxrqupWT8wcH5NaX" + ".png"

  const gc_image_url = "https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/" + "QmPZd2DFoqnhpSYsJcfnwvVegBqCneUPg8gynRoBjaSvmC" + ".png"

  const pitches_image_url = "pitches.jpg"


  const raffle = props.raffle


  const featured = props.featured



  //const [assetName, setAssetName] = useState('Snoop Dogg, Looks Rare')
  const assetID = raffle.assetID
  const assetName = raffle.assetName
  const assetImage = raffle.assetImage
  const startTime = raffle.startTime
  const endTime = raffle.endTime
  const raffleAddress = raffle.raffleAddress
  const winner = raffle.winner
  const purchaseLimit = raffle.purchaseLimit
  const meta = raffle.meta

  const totalTickets = raffle.totalTickets
  const ticketPrice = raffle.ticketPrice


  const [ended, setEnded] = useState(false)
  const [started, setStarted] = useState(true)

  const raffleInfo = {
    asset_id: assetID,
    image: assetImage,
    name: assetName,
    meta:meta,
    purchaseLimit: purchaseLimit,
    raffleAddress: raffleAddress
  }



  const [yourTickets, setYourTickets] = useState(0)
  const [purchasedTickets, setPurchasedTickets] = useState(0)
  


  const [timeEnd, setTimeEnd] = useState(10)
  const [timeStatus, setTimeStatus] = useState('')




  useEffect(()=>{

    if ( featured == raffle.assetID){

       setFeaturedRaffle({
       raffleInfo: {...raffleInfo},
       ticketPrice: ticketPrice,
       purchasedTickets: purchasedTickets,
       totalTickets: totalTickets,
       yourTickets: yourTickets,
       endTime: endTime,
       winner: winner,
       timeStatus: timeStatus,
       started: started,
       ended: ended,
       setRaffleAsset:setRaffleAsset,
       stake_address: stake_address

     })
   }

  },[yourTickets, purchasedTickets, stake_address, timeStatus])



  const cardThemes = {
    "cn":{title: "Clay Nation", image: cn_image_url, boxShadow: "#FF9C83", color:"bisque"},
    "gc":{title: "CN x Good Charlotte", image: gc_image_url, boxShadow: "#53C9EE", color: "bisque"},
    "pitches":{title: "Pitches at Clay Nation", image: pitches_image_url, boxShadow: "#c5b8ff", color: "bisque"}
  }


  async function fetchRaffleEntries(){

    let temp_stake_address;
    if (stake_address == ''){
      temp_stake_address = 'empty'
    }else{
      temp_stake_address = stake_address
    }

    let query_url = "https://claymarket.io/raffles/" + raffleAddress + '/' + temp_stake_address +'/'

    const raffleEntriesInfo = await fetch(query_url).then(response => response.json())
                                                                  .then(data => data)

    if ('user_purchased' in raffleEntriesInfo){

      setYourTickets(parseInt(raffleEntriesInfo['user_purchased'], 10))

    }                                                    

    if ('total_purchased' in raffleEntriesInfo){
      setPurchasedTickets(parseInt(raffleEntriesInfo['total_purchased'], 10) )
    }

  }



  useEffect(() => {

    
    fetchRaffleEntries()

  },[stake_address, raffleAddress])



 function getEpochEnd(time_end){


    if (!time_end){
      return
    }

    let now = Date.now()
    let time_dif = time_end - (now/1000)
    if (time_dif <= 60*60){
      if (time_dif <= 60){
        if (time_dif > 0){
           return '<1 minute'
         }else{
          return '0 minutes'
         }
       
      }else{
       return String(Math.floor(time_dif/60+1))+' minutes'
      }
    }else if (time_dif <= 60*60*24){
      if (time_dif <= 60*60){
        return '<1 hour'
      }else{
        let full_hours = Math.floor(time_dif/60/60)
        return String(full_hours) +'h ' + String(Math.floor((time_dif-full_hours*60*60)/60+1))+'m'
      }
    }else{
      let full_days = Math.floor(time_dif/60/60/24)
      let full_hours = Math.floor((time_dif - full_days * 60*60*24)/60/60)
      let full_minutes = Math.floor((time_dif - full_days * 60*60*24 - full_hours * 60 * 60)/60+1)
      return String(full_days) + 'd ' + String(full_hours) + 'h ' + String(full_minutes) + 'm'
    }

  }



function displayAmount(amount, precision){
    if (amount < 10000){
      return String(amount)
    }else if (amount < 1000000){
      return String((amount / 1000).toFixed(precision)) + 'K'
    }else if (amount < 1000000000){
      return String((amount / 1000000).toFixed(precision)) + 'M'
    }
  }



useEffect(() => {
  const interval = setInterval(() => updateTimeStatus(), 1000);
  return () => {
    clearInterval(interval);
  };
}, []);


useEffect(() => {
  const interval = setInterval(() => fetchRaffleEntries(), 30000);
  return () => {
    clearInterval(interval);
  };
}, []);


function updateTimeStatus(){

  let now = Math.floor(Date.now() / 1000)
      let time_str = ''

      if (startTime && endTime){

        if (now > startTime){

          if (now < endTime){


            let end_str = getEpochEnd(endTime)

            time_str = 'Ends in ' + end_str
            setTimeStatus(time_str)

            setEnded(false)

          }else{  

            setEnded(true)
            setTimeStatus('Raffle Ended')

          }

        }else{

          let start_str = getEpochEnd(startTime)

            time_str = 'Starts in ' + start_str
            setTimeStatus(time_str)


            setStarted(false)

           setEnded(false)
        }
    }
}




useEffect(()=>{


  updateTimeStatus()


},[startTime, endTime])






  return (

     <Card boxShadow={"#FFA500"} style={{cursor:'default'}} onClick={()=>{

      //setChosenCollection(collection)
      //updateCollection(collection)
    }
    }>
      <img src={'./RAFFLE_CARD.svg'} style={{height:'446px', marginTop:'-31px', marginLeft:'-31px'}} />

        <div style={{position:'absolute', top:'0px', display:'flex', flexDirection:'column', alignElements:'center', width:'100%', height:'10px'}}>
        

        <div style={{color: 'rgba(1, 1, 1, 0.8)', position:'absolute', top:'0px',zIndex:'100', display:'flex', justifyContent:'center', 
                    width:'100%', backgroundColor: "rgba(250, 250, 250, 0.5)", backdropFilter:'blur(10px)', borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}}>
                        


                      <div style={{display:'flex', justifyContent:'center', alignItems:'center', fontFamily:'ShortStack', color:'#3c005a'}}>
                        <BoltIcon />
                        <div>
                          {timeStatus} 
                        </div>
                        <BoltIcon />
                      </div>

                    </div>
              <div>
                <img className={'clay-nft'} src={assetImage} style={{objectFit: 'cover'}} />
              </div>



              <div style={{backgroundColor:'', marginTop:'1px'}}>


              <div  style={{backgroundColor:'', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                
                <div style={{marginLeft:'10px'}}>
                  
                  <div style={{color:'bisque', fontWeight:'bold', width:'120px'}}>{assetName}</div>

                  <div style={{color:'bisque', fontWeight:'bold', width:'100px'}}>(1/1)</div>


                  </div>



                <div style={{marginRight:'10px'}}>
                  
                  <div style={{display:'flex', justifyContent:'end', alignItems:'center'}}>

                    <div style={{marginRight:'5px', color: 'rgba(44, 179, 255, 1)'}}>
                      {yourTickets}
                    </div>

                    <ConfirmationNumberIcon style={{ color: 'rgba(44, 179, 255, 1)' }} />

                  </div>


                  <div style={{display:'flex', justifyContent:'end', alignItems:'center'}}>

                    <div style={{marginRight:'5px', color: 'rgba(196, 185, 255, 0.8)'}}>
                      {purchasedTickets}/{totalTickets}
                    </div>

                    <LocalActivityIcon style={{ color: 'rgba(196, 185, 255, 0.8)' }} />

                  </div>

                </div>

                </div>


                <RaffleButton
                  started={started}
                  ended={ended}
                  totalTickets={totalTickets}
                  purchasedTickets={purchasedTickets}
                  ticketPrice={ticketPrice}
                  yourTickets={yourTickets}
                  endTime={endTime}
                  timeStatus={timeStatus}
                  winner={winner}
                  stake_address={stake_address}
                  setRaffleAsset={setRaffleAsset}
                  raffleInfo={raffleInfo}
                 />


              </div>
        </div>

      </Card>
    );
}



function Raffle(props) {

  const setFeaturedRaffle = props.setFeaturedRaffle
  const raffleAsset = props.raffleAsset
  const setRaffleAsset = props.setRaffleAsset
  const matchesS = props.matchesS 
  const matchesM = props.matchesM
  const matchesL = props.matchesL
  const isSideBarOpen = props.isSideBarOpen



  const raffles = [

    {
        assetID: '6e02a35cf473bb8de2b3b8f1234f04b7eb0258408e51d2f74ec47b9e536e6f6f70446f676747696e4a75696365',
        assetName: 'SNOOP DOGG, GIN & JUICE',
        assetImage: 'announcements/snoop/SnoopDoggGinJuice.gif',
        startTime: (1679131242 + 218400),
        endTime: (1679349642 + 432000),
        slotStart: 87782349,
        slotEnd: 88215249,
        raffleAddress: 'addr1vxucepp250acpng44r4rr6qa05dpkpsv736zfe0293a25mccvx3t0',
        winner: 'stake1uy2yxwyc49lka2hreyxlk6438yzpwmst403wuyc8gqkj2yqnxuess',
        purchaseLimit: 200,
        totalTickets: 2000,
        ticketPrice: 20,

        meta: {
          Type: 'Scene',
            Collection: 'Icons by Clay Nation',
            Edition: '1/1'
        }
    },

    {
        assetID: '6e02a35cf473bb8de2b3b8f1234f04b7eb0258408e51d2f74ec47b9e536e6f6f70446f67674c6f6f6b7352617265',
        assetName: 'SNOOP DOGG, LOOKS RARE',
        assetImage: 'announcements/snoop/snoop.gif',
        startTime: (1677730800 + 49320),
        endTime: 1677730800,
        raffleAddress: 'addr1vxs95wvwmcxp8lcpw4wfk9a877f5uxqr0p9kuyk0x3nrw6sg34as7',
        winner: 'stake1u8rxpvpwq5rqx5a5zym7gft7l8229p5nmhprvuy6swtzf0s5d29xm',
        purchaseLimit: 200,

        totalTickets: 2000,

        ticketPrice: 20,

        meta: {
          Type: 'Portrait',
            Collection: 'Icons by Clay Nation',
            Edition: '1/1'
        }
    }

  ]


  return (

    <div style={{display:'flex', justifyContent: isSideBarOpen && matchesS ? 'space-between' : 'center', minHeight:'500px',width:'80%', 
    flexWrap: 'wrap', backgroundColor:'', alignItems:'center', marginTop:'0px',
    marginLeft: isSideBarOpen && matchesS ? '320px' : '0px'}}>

     <div style={{display:'flex', justifyContent:'center', flexWrap:'wrap', backgroundColor:'', width:'100%'}}>


      {

        raffles.map((raffle) => {

          return(
            <div key={raffle.assetID} style={{width:'275px', height:'390px', margin:'20px', marginLeft:'30px',backgroundColor:''}}>
          
              <CollectionCard collection={'cn'} 

                raffleAsset={raffleAsset}
                setRaffleAsset={setRaffleAsset}
                setFeaturedRaffle={setFeaturedRaffle}
                raffle={raffle}

                featured={'6e02a35cf473bb8de2b3b8f1234f04b7eb0258408e51d2f74ec47b9e536e6f6f70446f676747696e4a75696365'}

               />

            </div>
            )

        })

      }


        

        {/*
        <div style={{width:'275px', height:'390px', margin:'20px', marginLeft:'30px',backgroundColor:''}}>
          
          <CollectionCard collection={'cn'} 

            raffleAsset={raffleAsset}
            setRaffleAsset={setRaffleAsset}

            setFeaturedRaffle={setFeaturedRaffle}

          />


        </div>
        */}
       
      </div>

    </div>
  
  );
}

export default Raffle;


