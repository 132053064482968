import { useState, useEffect, useContext } from 'react'

import { useSearchParams } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import './CollectionCard.css'

import './Collections.css'


import CN from './Images/claynation.png';
import GC from './Images/GC.png';
import pitch from './Images/pitches.png';


import styled,  { keyframes } from 'styled-components';

const Card = styled.div`
    position: absolute;
    text-align: center;
    border-radius: 10px;
    width: 272px;
    height: 384px;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    color: black;
    display: block;
    background-color: transparent;
    text-align: center;

    &:hover {
      box-shadow: 0 0px 28px ${props => props.boxShadow || "#FF9C83"};
      cursor: pointer;
    }
  `

const CardText = styled.div`
    margin-top: 2px;
    position: relative;
    font-family: ShortStack;
    font-size: 1.2rem;
    color: ${props => props.color || "#FF9C83"};
    opacity: 0.9;
`


 const cardCollections = {
    "cn":"40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728",
    "gc":"94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768",
    "pitches":"13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e"
  }


const validCollections = {
    
    claynation: 'cn',
    goodcharlotte: 'gc',
    pitches: 'pitches',
    pants: 'pants'

}



const validCollectionsAbr = {

    cn: 'claynation',
    gc: 'goodcharlotte',
    pitches: 'pitches',
    pants: 'pants'

}

/*
const policyCollections = {
  "40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728":"cn",
  "94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768":"gc",
  "13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e":"pitches"
}
*/

export function CollectionCard(props){

  let [searchParams, setSearchParams] = useSearchParams();

  const collection = props.collection

  const setChosenCollection = props.setChosenCollection

  const collectionInfo = props.collectionInfo

  const updateCollection = props.updateCollection


  //const cn_image_url = "https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/" + "QmfKc4QcXrriUiHhao9TFqvWx8G6iitxrqupWT8wcH5NaX" + ".png"
  //const gc_image_url = "https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/" + "QmPZd2DFoqnhpSYsJcfnwvVegBqCneUPg8gynRoBjaSvmC" + ".png"
  //const pitches_image_url = "pitches.jpg"

  //const cn_image_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/collections/CN.svg'
  const cn_image_url = CN
  //const gc_image_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/GC_Tumbnail_2d-removebg-preview.png'

  const gc_image_url = GC

  const pitches_image_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/collections/PITCHES.svg'



  const [floor, setFloor] = useState('-')
  const [volume, setVolume] = useState('-')



  async function getClayTokenPrice(){

    let query_url = 'https://claymarket.io/api/claytoken/price/'

    let temp = await fetch(query_url).then(response => response.json())
                                   .then(data => data)

    let temp_exchange_rate = 0                           
    if ('clay_price' in temp){
        temp_exchange_rate = parseFloat(temp['clay_price'])
    }

    return temp_exchange_rate
  }



  async function updateInfo(){

    let clay_token_price = await getClayTokenPrice()

    if ('all_time_volume' in collectionInfo){

      setVolume(displayAmount(collectionInfo['all_time_volume']/1000000,1))

    }

    let ada_floor = 0
    let clay_floor = 0


    if ('ada_floor' in collectionInfo){
      ada_floor = parseInt(collectionInfo['ada_floor'], 10) ? parseInt(collectionInfo['ada_floor'], 10) : 0
    }

    if ('clay_floor' in collectionInfo){
      clay_floor = parseInt(collectionInfo['clay_floor'], 10) ? parseInt(collectionInfo['clay_floor'], 10) : 0
    }



    ada_floor = Math.round(ada_floor / 1000000)
    clay_floor = Math.round(clay_floor/10000 * clay_token_price)


    let temp_floor;

    if (clay_floor > 0){
      temp_floor = Math.min(ada_floor, clay_floor)
    }else{
      temp_floor = ada_floor
    }

    setFloor(temp_floor)


  }






  useEffect(()=>{

    updateInfo()

  }, [collectionInfo])
  


  const cardThemes = {
    "cn":{title: "Clay Nation", image: cn_image_url, boxShadow: "#FF9C83", color:"bisque"},
    "gc":{title: "Good Charlotte", image: gc_image_url, boxShadow: "#53C9EE", color: "bisque"},
    "pitches":{title: "Pitches", image: pitches_image_url, boxShadow: "#c5b8ff", color: "bisque"}
  }




function displayAmount(amount, precision){
    if (amount < 10000){
      return String(amount)
    }else if (amount < 1000000){
      return String((amount / 1000).toFixed(precision)) + 'K'
    }else if (amount < 1000000000){
      return String((amount / 1000000).toFixed(precision)) + 'M'
    }
  }



  return (

    <>


     <div className={collection.toUpperCase()}

     style={{fontFamily:'ShortStack', transform:'scale(1.8)'}}

      onClick={()=>{
        

        if (collection in validCollectionsAbr){

            setChosenCollection(collection)
            updateCollection(collection)
            setSearchParams({collection:validCollectionsAbr[collection]})
            
        }
        

      }}

     >


      <div className={collection.toUpperCase() + '-background'}>


        <img className={collection.toUpperCase() + '-img'} src={cardThemes[collection].image} alt={collection.toUpperCase() + "-image"} 
        style={collection == 'gc' ? {width:'120px', marginTop:'5px', marginLeft:'-10px'} : collection == 'cn' ? {width:'130px'} : {}} />

        <div className={collection.toUpperCase() + '-title'}> {cardThemes[collection].title.toUpperCase()} </div>
        
        


        <div style={{display:'flex', height:'100px', width:'100%', 
                     alignItems:'center', justifyContent:'center', backgroundColor:''}}>


          <div className={collection.toUpperCase() + '-volume'} style={{marginLeft:'-40px', marginTop:'-60px', width:'100px'}} >
            <div className={collection.toUpperCase() + '-volume-txt'}>FLOOR</div>
            <div className={collection.toUpperCase() + '-volume-nmb'}>₳ {floor}</div>
          </div>

        </div>



        {/*<div className={collection.toUpperCase() + '-volume'}>
            <div className={collection.toUpperCase() + '-volume-txt'}>VOLUME</div>
            <div className={collection.toUpperCase() + '-volume-nmb'}>{volume}</div>
          </div>

          <div className={collection.toUpperCase() + '-floor'}>
            <div className={collection.toUpperCase() + '-volume-txt'}>FLOOR</div>
            <div className={collection.toUpperCase() + '-volume-nmb'}>₳ {floor}</div>
          </div>*/}



      </div>

     </div>



    {/*
     <Card boxShadow={cardThemes[collection].boxShadow} onClick={()=>{

      setChosenCollection(collection)
      updateCollection(collection)}
    }>
      <img src={'./'+ collection.toUpperCase() + '_CARD.svg'} style={{height:'446px', marginTop:'-31px', marginLeft:'-31px'}} />

        <div style={{position:'absolute', top:'0px', display:'flex', flexDirection:'column', alignElements:'center', width:'100%', height:'10px'}}>
        
              <div>
                <img className={'clay-nft'} src={cardThemes[collection].image} />
              </div>

              <div style={{position:'relative'}} style={{backgroundColor:'', marginTop:'20px'}}>

                <CardText color={cardThemes[collection].color}>{cardThemes[collection].title}</CardText>

             
                <div className={"clay-id"} style={{marginTop:'20px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <img src='./Cardano_light.svg' style={{width:'30px', marginRight:'5px',zIndex:'100'}} />
                    <CardText color={cardThemes[collection].color}>{tradedVolume > 0 ? displayAmount(tradedVolume/1000000,1) : '-'} Volume</CardText>
                </div>
      
                <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', marginLeft:'-5px'}}>
                     
                    </div>
              </div>
        </div>

      </Card>
    */}

    </>
    );
}



function ChooseCollection(props) {

  const setChosenCollection = props.setChosenCollection

  const matchesS = props.matchesS 
  const matchesM = props.matchesM
  const matchesL = props.matchesL

  const isSideBarOpen = props.isSideBarOpen

  const updateCollection = props.updateCollection



  const [collectionsInfo, setCollectionsInfo] = useState({})



/*
  useEffect(()=>{
      get_collections_overview()
  }, [])

    
  async function get_collections_overview(){

      let query_url = 'https://claymarket.io/api/collections/overview/' 
      let collections_overview = await fetch(query_url).then(response => response.json())
                                       .then(data => data)
      if ('error' in collections_overview){
        return 
      }
      setCollectionsInfo(collections_overview) 
      console.log(collections_overview) 

  }
*/


  async function getClayTokenPrice(){

    let query_url = 'https://claymarket.io/api/claytoken/price/'
    let temp = await fetch(query_url).then(response => response.json())
                                   .then(data => data)

    let temp_exchange_rate = 0                           
    if ('clay_price' in temp){
        temp_exchange_rate = parseFloat(temp['clay_price'])
    }

    return temp_exchange_rate
  }



  function displayAmount(amount, precision){
    if (amount < 10000){
      return String(amount)
    }else if (amount < 1000000){
      return String((amount / 1000).toFixed(precision)) + 'K'
    }else if (amount < 1000000000){
      return String((amount / 1000000).toFixed(precision)) + 'M'
    }
  }


  //https://claymarket.io/api/stats/collection/all/


async function get_collections_overview(){

  let query_url = 'https://claymarket.io/api/stats/collection/all/'

  let collections_overview = await fetch(query_url).then(response => response.json())
                                       .then(data => data)
  
  if ('error' in collections_overview){
    return 
  }

  setCollectionsInfo(collections_overview)

}





  useEffect(()=>{
      get_collections_overview()
  }, [])




  
  return (

    <div style={{display:'flex', justifyContent: isSideBarOpen && matchesS ? 'space-between' : 'center', minHeight:'500px',
      width:'80%', flexWrap: 'wrap', backgroundColor:'', alignItems:'center', marginTop:'0px', backgroundColor: '', height:'100%',
      marginLeft: isSideBarOpen && matchesS ? '320px' : '0px', marginTop:'', marginTop: matchesL ? '0px' : '40px'}}>

     <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexWrap:'wrap', backgroundColor:'', width:'100%'}}>

        <div style={{width:'275px', height:'390px', margin:'20px', marginLeft:'30px',backgroundColor:'', 
                    display:'flex', justifyContent:'center', alignItems:'center'
      }}>
    
            <CollectionCard collection={'cn'} setChosenCollection={setChosenCollection} updateCollection={updateCollection}
            collectionInfo={collectionsInfo && cardCollections['cn'] in collectionsInfo ? collectionsInfo[cardCollections['cn']] : {}}   />
         
        </div>

       <div style={{width:'275px', height:'390px', margin:'20px', marginLeft:'30px',backgroundColor:'', 
                    display:'flex', justifyContent:'center', alignItems:'center'
      }}>
          <CollectionCard collection={'gc'} setChosenCollection={setChosenCollection} updateCollection={updateCollection}
          collectionInfo={collectionsInfo && cardCollections['gc'] in collectionsInfo ? collectionsInfo[cardCollections['gc']] : {}}  />
        </div>

       <div style={{width:'275px', height:'390px', margin:'20px', marginLeft:'30px',backgroundColor:'', 
                    display:'flex', justifyContent:'center', alignItems:'center'
      }}>
          <CollectionCard collection={'pitches'} setChosenCollection={setChosenCollection} updateCollection={updateCollection}
         collectionInfo={collectionsInfo && cardCollections['pitches'] in collectionsInfo ? collectionsInfo[cardCollections['pitches']] : {}} />
        </div>
      </div>

     

    </div>
  
  );
}

export default ChooseCollection;