import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Claim from './Claim';

import PrivacyPolicy from './components/PrivacyPolicy'

import TermsAndConditions from './components/TermsAndConditions'

import CookiesPolicy from './components/CookiesPolicy'

import ClaimContext from './components/ClaimContext'


import HandlesPreProd from './components/HandlesPreProd/HandlesPreProd'
import Handles from './components/Handles/Handles'

import GC_PANTS from './components/GC_PANTS/GC_PANTS'

import Ledger from './components/Ledger/Ledger'

import SandboxUnlock from './components/SandboxUnlock/SandboxUnlock'
import SandboxUnlockInfo from './components/SandboxUnlock/SandboxUnlockInfo'

import WalletLink from './components/WalletLink_Dark/WalletLink'

import WalletLinkLedger from './components/WalletLink_Ledger/WalletLink'

import ClayDash from './components/ClayDash/Pages/Dashboard'
import ClayDashLogin from './components/ClayDash/Pages/LogInPortal'

import ClayDash_TC from './components/ClayDash/Pages/TermsAndConditions'

import PFP_Generator from './components/PFP_Generator/PFP_Generator'

// import FESTIVE_SALE from './components/FESTIVE_SALE/FESTIVE_SALE'

import PitchesStaking from './components/PitchesStaking/PitchesStaking'

import Hoodie_Sale from './components/Hoodie_Sale/Hoodie_Sale'

import Stems_Claim from './components/Stems_Claim/Stems_Claim'

import Baked_Claim from './components/Baked_Claim/Baked_Claim'


import PAGE_NOT_FOUND from './components/PAGE_NOT_FOUND/PAGE_NOT_FOUND'

import CommunityCollectiblesSale from './components/CommunityCollectiblesSale/CommunityCollectiblesSale'

import NEW_BAKED_CLAIM from './components/NEW_BAKED_CLAIM/NEW_BAKED_CLAIM'

import Baking_Claim from './Baking_Claim';


import BakedLock from './components/BakedLock/BakedLock';

import BakedLock_ComingSoon from './components/BakedLock_ComingSoon/BakedLock_ComingSoon';

import BakedLock_End from './components/BakedLock_End/BakedLock_End';

// import Models_Wallet from './Models_Wallet';

// import Shoes_Sale from './components/Shoes_Sale/Shoes_Sale'

function MyRoutes() {
        return(
        <div>
        <Router>
          <Routes>

            <Route exact path="/" element={<Claim />} />

             <Route exact path="/baked-lock" element={<BakedLock />} />
             {/*<Route exact path="/baked-lock" element={<BakedLock_End />} />*/}
             {/*<Route exact path="/123claytest" element={<BakedLock />} />*/}

             <Route exact path="/clay-baking" element={<Baking_Claim />} />


            {/*<Route exact path="/clay-models" element={<Models_Wallet />} />*/}

            <Route exact path="/handles/preprod/" element={<HandlesPreProd />} />
            <Route exact path="/handles/" element={<Handles />} />


            <Route exact path="/gc-pants/" element={<GC_PANTS />} />

            <Route exact path="/ledger/" element={<Ledger />} />
            
            <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route exact path="/termsandconditions" element={<TermsAndConditions />} />
            <Route exact path="/cookiepolicy" element={<CookiesPolicy />} />
            

            <Route exact path='/wallet-link' element={<WalletLink />} />
            <Route exact path='/wallet-link-ledger' element={<WalletLinkLedger />} />

            
            <Route exact path='/sandbox-unlock' element={<SandboxUnlock />} />
            <Route exact path='/sandbox-unlock/info' element={<SandboxUnlockInfo />} />


            <Route exact path='/login' element={<ClayDashLogin />} />
            <Route exact path='/dashboard' element={<ClayDash />} />
            <Route exact path='/claydash' element={<ClayDash />} />
            <Route exact path='/claydash/terms-and-conditions' element={<ClayDash_TC />} />

            <Route exact path='/festive-pfp' element={<PFP_Generator />} />

            {/*<Route exact path='/valentines-sale' element={<Shoes_Sale />} />*/}


            <Route exact path='/pitches-staking' element={<PitchesStaking />} />

            <Route exact path='/pitch-staking' element={<PitchesStaking />} />

            <Route exact path='/baked-hoodie' element={<Hoodie_Sale />} />

            <Route exact path='/stems-claim' element={<Stems_Claim />} />

            <Route exact path='/baked-claim' element={<NEW_BAKED_CLAIM />} />

            <Route exact path='/community-sale' element={<CommunityCollectiblesSale />} />


            <Route path='*' element={<PAGE_NOT_FOUND />} />

 
          </Routes>
        </Router>
      </div>
  )
}

export default MyRoutes;