import { useState, useEffect, useContext } from 'react'

import {Buffer} from 'buffer'

import CircularProgress from '@mui/material/CircularProgress';

import './PlotCard.css'

import styled,  { keyframes } from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';

import detailed_plot_info from '../../detailed_plot_info.json'

import {MainClaimContext} from '../ClaimContext'

const pitches_img = {

  sv:{
    s:'QmQek5t1rVtgqnjSYSqbWHKjo6ZMAqm9jDpDj3k8HuwNAT',
    m:'QmRuXUSRK9AB1tTiricy3nM7DepHz67MjsQ3k22QVWeRDA',
    l:'Qmathg6tgHrrDCgtXemb2kgjvgBZcMq6oKs8Y4dXJndf2a'
  },
  bn:{
    s:'QmaNodcLPYYhnLjfBzJ2zcByKUiqqMB11mQKhuDmiSXYBT',
    m:'Qmeq4ZbXznRtdjSrEPUNaK6evJuKPKh3gucrcLjHCW5DSU',
    l:'QmY8jRC8RxvkrH3AxHVc8UpUzBLFKoBe5rUsaTYbmXSXGo'
  },
  uw:{
    s:'QmUPBm4jM3Q7EBYEdZE3FGTVhG3z12tywBah18Rt9iKbcy',
    m:'QmNNkwSvnftm18XyZZQ5NxJmnG4tbYE4tjHXd9SsGFtvJF',
    l:'QmQ2fkmof1zF3p8mEU6NSnAKGrmxXVYNrNF8PxcZhNAyF6'
  }

}

const coordinatesColor={
  sv:'#9bc075',
  bn:'#f6aa2b',
  uw:'#bad7f1'
}

const size_dict = {
  s:'Small',
  m:'Medium',
  l:'Large'
}

const zone_dict = {
  sv:'Sonic Village',
  bn:'Baked Nation',
  uw:'Underworld'
}


  const ButtonText = styled.h1`
    position: relative;
    font-size: 1.8em;
    font-family: 'shortstack';
    letter-spacing: 0.1em;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    color: #fff;
    z-index: 4;
  `

const pitches_policy = '13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e'





function PlotCard(props) {

  const selectedPlot = props.selectedPlot

  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)


  //const image_url = "https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/"+ipfs+".png"
  const setSelectedPlot = props.setSelectedPlot

  const setSellAsset = props.setSellAsset

  const [isFetching, setIsFetching] = useState(false)

  const cardCollection = 'pitches'
  const assetBaseName = 'ClayNationPitch'

  /*
  const [asset_info, setAsset_info] = useState()
  const [assetStatus, setAssetStatus] = useState('listed')
  const [asset_id, setAsset_id] = useState('')
  const [tx_hash, set_tx_hash] = useState('')
  */

  const [plotInfo, setPlotInfo] = useState()


useEffect(()=>{
  fetchPlotStatus()
},[stake_address])


function openSellPopUp(assetInfo){
  setSellAsset(assetInfo)
}

  function get_image_div(){}



  const PlotImage = () => {
    let cur_zone = detailed_plot_info[selectedPlot].zone
    let cur_size = detailed_plot_info[selectedPlot].size

    let cur_image_url = pitches_img[cur_zone][cur_size]

    let cur_image = "https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/" + cur_image_url + '.png'


    return (

    <div>
      <img src={cur_image} style={{height:'160px', borderRadius:'10px', opacity:'0.9'}} />
    </div>

    )
  }

function get_image_url(ipfs){
  return "https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/"+ipfs+".png"
}


  async function fetchPlotStatus(){

    setIsFetching(true)

    let asset_id = "ClayNationPitch" + String(detailed_plot_info[selectedPlot].serial)
    asset_id = Buffer.from(asset_id,'utf8').toString('hex')
    asset_id = pitches_policy + asset_id

    //console.log(asset_id)

    let query_url = 'https://claymarket.io/api/filter/listings/?collection=pitches&asset_id=' + asset_id

    let asset_listing_info = await fetch(query_url).then(response => response.json())
                                     .then(data => data)

    //console.log(asset_listing_info)  



    if ('listings' in asset_listing_info){
      asset_listing_info = asset_listing_info['listings'][0]
    }else{
      return
    }


    let ada_price = asset_listing_info['ada_price']
    let clay_price = asset_listing_info['clay_price']

    let accumulatedToken = 0


    let cur_zone = detailed_plot_info[selectedPlot].zone
    let cur_size = detailed_plot_info[selectedPlot].size

    let cur_image_url = get_image_url(pitches_img[cur_zone][cur_size])

    let tx_hash = asset_listing_info['tx_id'] + '#' + asset_listing_info['tx_index']


    let listing_owner = ''

    if (asset_listing_info['owner_stake_address']){
        listing_owner = asset_listing_info['owner_stake_address']
    }else{
        listing_owner = asset_listing_info['stake_address']
    }

    let assetStatus = ''
    if (ada_price > 0 || clay_price > 0){

            if (listing_owner == stake_address){
                assetStatus = 'updatable'
            }else{
                assetStatus = 'listed'
            }
    }else{

        if (listing_owner == stake_address){
          assetStatus = 'sellable'
        }else{
          assetStatus = 'not listed'
        }
            
      
        }



   let temp_asset_info = {
                            meta:{coordinates: selectedPlot,
                                zone: cur_zone,
                                size: cur_size}, 
                          assetPrice:{"ada_price":ada_price, "clay_price":clay_price},
                          accumulatedToken: accumulatedToken
                          }

                        

    setPlotInfo(
      {
        asset_id:asset_id, 
        tx_hash:tx_hash, 
        image: cur_image_url,
        assetStatus:assetStatus,
        meta:{coordinates: selectedPlot,
                                zone: zone_dict[cur_zone],
                                size: size_dict[cur_size]}, 
        collection: cardCollection,

        assetPrice:{"ada_price":ada_price, "clay_price":clay_price},
                          accumulatedToken: accumulatedToken,

                          address:asset_listing_info['address'],
                          owner_stake_address: listing_owner
        }
      )

    setIsFetching(false)
  }


  useEffect(()=>{
    setPlotInfo()
    fetchPlotStatus()
  },[selectedPlot])

  


  function displayName(asset_id){

  if (!asset_id){
    return
  }
  let cur_policy = asset_id.slice(0,56)

  let baseName = assetBaseName[cur_policy]


  let serial = Buffer.from(asset_id.slice(56,),'hex').toString('utf8').slice(baseName.length,)


  return serial


}


function displayPrice(price){
  let ada_price = price['ada_price']
  let clay_price = price['clay_price']



  if (ada_price > 5000000){
    return(
      <div style={{display:'flex', alignItems:'center'}}>
      <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Cardano.svg' style={{width:'25px', zIndex:'0'}} />
        {displayAmount((ada_price/1000000).toFixed(0),2)}
      </div>
    )
  }else if(clay_price > 0){
    return(
      <div style={{display:'flex', alignItems:'center'}}>
        <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CLAY_TOKEN_220x220.png' style={{width:'25px', zIndex:'0'}} />
        {displayAmount((clay_price/10000).toFixed(0),2)}
      </div>
      )
  }
}



  function displayAmount(amount, precision){
    if (amount < 10000){
      return String(amount)
    }else if (amount < 1000000){
      return String((amount / 1000).toFixed(precision)) + 'K'
    }else if (amount < 1000000000){
      return String((amount / 1000000).toFixed(precision)) + 'M'
    }
  }



  return (


  <div className="plot-card">

  <div style={{display:'flex', position:'absolute',flexDirection:'column', justifyContent:'space-between', height:'90%', width:'100%'}}>

    <div style={{fontFamily:'ShortStack'}}>

      <div style={{position:'absolute',display:'flex', width:'92%',justifyContent:'end', marginTop:'8px'}}>
        <div style={{color:'bisque', fontSize:'22px', cursor:'pointer'}}

        onClick={()=>{
          setPlotInfo()
          setSelectedPlot(null)
        }}

        > X </div>
      </div>

      <div style={{color:'bisque', marginTop:'10px', marginBottom: '5px', fontSize:'20px', fontWeight:'bold'}}>
       Pitch #{detailed_plot_info[selectedPlot].serial}
      </div>

        <PlotImage />
      
      <div style={{color:coordinatesColor[detailed_plot_info[selectedPlot].zone], marginTop:'5px'}}>
        {selectedPlot}
      </div>

      <div style={{color:'bisque'}}>
        {zone_dict[detailed_plot_info[selectedPlot].zone]}
      </div>

      <div style={{color:'bisque'}}>
        {size_dict[detailed_plot_info[selectedPlot].size]}
      </div>
    </div>

{/* Price */}


{isFetching || !plotInfo ?

<div style={{position:'relative', display:'flex', alignItems:'center', justifyContent:'center',
            height:'50px', width:'100%',zIndex:'250', marginTop:'80px'}}> 
                <CircularProgress style={{zIndex:'200', width:'30px', height:'30px', color: "#53c8ee"}} />
 </div>

:

  


                <div style={{position:'relative', marginTop:'40px'}}>

                {/* ACCUMULATED TOKEN */}
                {plotInfo.accumulatedToken > 0 ? 
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'2px', height:'30px', backgroundColor:''}}>
                  <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/TokenSpace.png' style={{position:'absolute', width:'110px', marginLeft:'0px'}} />
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', marginLeft:'-5px'}}>
                     <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CLAY_TOKEN_220x220.png' style={{width:'20px', marginRight:'2px', zIndex:'100'}} />
                     <span className={cardCollection.toUpperCase() + "-tokens"}>{plotInfo.accumulatedToken}</span>
                    </div>
                </div>

                : <div style={{height:'30px'}}></div>}


                {/* SELL BUTTON / PRICE */}
                {plotInfo.assetStatus == 'sellable' ?
                  <div style={{ position:'relative',display:'flex', justifyContent:'center', marginTop:'0px', marginBottom:'0px', width:'100%', backgroundColor:'', zIndex:'100'}}>
          
                    <button className={cardCollection.toUpperCase()+"-SellBtn"} style={{transform: 'scale(0.75)', zIndex:'100'}} onClick={(event)=>{
                      event.stopPropagation()


                      
                      openSellPopUp(plotInfo)
                      

                    }}>

                      <ButtonText style={{fontSize:'18px'}}>
                        SELL 
                      </ButtonText>
                    </button>
                  </div>


                  
                    : plotInfo.assetStatus == 'listed'  ?

                    <div onClick={(event)=>{

                      openSellPopUp(plotInfo)

                    }}>
                     <div style={{display:'flex', justifyContent:'center',width:'100%'}}>

                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'+cardCollection.toUpperCase()+'_PRICE_TAG.svg'} style={{position:'absolute', width:'200px', marginLeft:'-40px', marginTop:'-20px', backgroundColor:''}} />
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'60px', marginTop:'0px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            <div className={"CN-ADA-back"}>
                              <div style={{color:'white'}}>{displayPrice(plotInfo.assetPrice)}</div> 
                            </div>

                            <div className={"BUY-NFT"}>
                              BUY NFT
                            </div>
                          </div>
                      </div>
                      </div>

                    </div>



                    :  plotInfo.assetStatus == 'not listed' ?

                    <div>


                    <div style={{display:'flex', justifyContent:'center',width:'100%'}}>

                      <div style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px', marginRight:'165px'}}>
                         <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/NOT_FOR_SALE.svg'} style={{position:'absolute', width:'160px', marginLeft:'0px', marginTop:'0px'}} />
                      
                      </div>
                  </div>

                    
                    {/*
                    <div onClick={(event)=>{

                      openSellPopUp(plotInfo)

                    }}>


                    <div style={{display:'flex', justifyContent:'center',width:'100%'}}>

                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={'./NOT_FOR_SALE.svg'} style={{position:'absolute', width:'160px', marginLeft:'0px', marginTop:'0px'}} />
                       

                        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'20px', marginTop:'0px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            <div className={"BUY-NFT"}>
                              MAKE OFFER
                            </div>
                        </div>


                      </div>

                    </div>



                    </div>

                  */}

                  </div>


                    :


                    /* UPDATE | DELIST */
                    plotInfo.assetStatus == 'updatable' ?

                    <div onClick={(event)=>{

                      event.stopPropagation()

                      openSellPopUp(plotInfo)

                    }}>
                     <div style={{display:'flex', justifyContent:'center',width:'100%'}}>
                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'+cardCollection.toUpperCase()+'_PRICE_TAG.svg'} style={{position:'absolute', width:'200px', marginLeft:'-40px', marginTop:'-20px', backgroundColor:''}} />
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'60px', marginTop:'0px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            <div className={"CN-ADA-back"}>
                              <span style={{color:'white'}}>{displayPrice(plotInfo.assetPrice)}</span> 
                            </div>

                            <div className={"BUY-NFT"}>
                              UPDATE | DELIST
                            </div>

                          </div>
                      </div>

                    </div>
                    </div>

                   : null}

                  </div>
         }


    {/*
    <div>
       <img src={'./CN_PRICE_TAG.svg'} style={{position:'absolute', width:'200px', marginLeft:'-115px', marginTop:'-50px', backgroundColor:''}} />              
    </div>
    */}

    </div>

</div>

  )
}

export default PlotCard




