import {Suspense, useContext} from "react";

import { Canvas } from '@react-three/fiber';
import { useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useState, useRef, useEffect } from 'react'
import {sRGBEncoding, AxesHelper, GridHelper} from 'three';
import * as THREE from 'three'

import { extend, useThree } from '@react-three/fiber';

import Controls from './Controls'

import MapPlane from './MapPlane'


import '../ZoomButton.css'
import './ClayMap.css'

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import pixel_to_plot from './pixel_to_plot.json'

import pitches_meta from '../../pitches_meta.json' 



import {MainClaimContext} from '../ClaimContext'

const theme = createTheme({
  typography: {
    fontFamily: [
      'shortstack',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});


function Spiral(){

  useEffect(() => {
    //console.log('Loading...')
  },[])

 return(
  <div>

  </div>
  )
}



const mapCenter = {'x':662,'y':442}
const plotSizes = {'Small':[0.0465,0.0465,0.01], 'Medium':[0.115,0.115,0.01], 'Large':[0.175,0.175,0.01]}
const res = 8192

const pitches_policy = '13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e'


function ClayMap(props) {


    const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)

    const group1 = new THREE.Group();
    const mouse = new THREE.Vector2()
    const [cubeSelected, setCubeSelected] = useState(false)
    const [interruptSelect, setInterruptSelect] = useState(true)  
    const mapCenter = {'x':662,'y':442}
    const geometry = new THREE.BoxGeometry(5,5);
    const material = new THREE.MeshBasicMaterial({
    color: 0x00ff00
    });
    const cube = new THREE.Mesh(geometry, material);
    const [plot,setPlot] = useState(null)
    const [plots, setPlots] = useState(null)
    const [clickDistance, setClickDistance] = useState(null)
    const [leftSideBarOpen, setLeftSideBarOpen] = useState(true)
    const [rightSideBarOpen, setRightSideBarOpen] = useState(true)
    const [ownedPitches, setOwnedPitches] = useState()
    const [adaBalance, setAdaBalance] = useState()
    //const [address, setAddress] = useState()
    const [isDiscordInput, setIsDiscordInput] = useState()

    //const [heightPointer, setHeightPointer] = useState(0)
    //const [circlePointer, setCirclePointer] = useState(true)
    
    const isSmall = !useMediaQuery(theme.breakpoints.up('sm'));
    const circlePoints = new THREE.Path().absarc(0, 0, 0.25, 0, Math.PI * 2).getPoints(64)

    const lineGeometry = new THREE.BufferGeometry().setFromPoints(circlePoints)

    const [cameraPositionZ, setCameraPositionZ] = useState(32)


    const pitchesFilters = props.pitchesFilters


    const heightPointer = pitchesFilters.stickPointers ? 10 : 0
    const circlePointer = pitchesFilters.circlePointers


    const selectedPlot = props.selectedPlot 
    const setSelectedPlot = props.setSelectedPlot

    const appliedFilters = props.appliedFilters



    const yourAssetsFilters = props.yourAssetsFilters

    const yourAssets = yourAssetsFilters.yourAssets
    const setYourAssets = yourAssetsFilters.setYourAssets

    const listedOnly = yourAssetsFilters.listedOnly
    const setListedOnly = yourAssetsFilters.setListedOnly

    const notListedOnly = yourAssetsFilters.notListedOnly
    const setNotListedOnly = yourAssetsFilters.setNotListedOnly


    const [listingPlots, setListinglots] = useState([])

    const [zoomClicked, setZoomClicked] = useState(false)



    const searchQuery = props.searchQuery
    const setSearchQuery = props.setSearchQuery


    const pitchesFound = props.pitchesFound
    const setPitchesFound = props.setPitchesFound


    const [clayPrice, setClayPrice] = useState(0) 





    useEffect(()=>{

      get_clay_price()

    },[])


    async function get_clay_price(){

       let fetched_current_stats = await fetch('https://claymarket.io/api/claytoken/price/').then(response => response.json())
                                                                    .then(data => data)

       let temp_clay_price = fetched_current_stats['clay_price'].toFixed(6)
       //console.log(temp_clay_price)
       setClayPrice(temp_clay_price)

    } 



    const onPlotClick = (plotPosition,plotSize) => {

      if (isSmall){
        setLeftSideBarOpen(false)
      }

      try{
    
       if (plotSize[0] == 0){
    setPlot(null)
       }else{
      setPlot([{'position':[plotPosition[0],plotPosition[1],plotPosition[2]+0.01],'size':[plotSize[0] + 0.001,plotSize[1] + 0.001,plotSize[2]+ 0.001],'id':0}])
       }

       }catch{}

    }



  useEffect(()=>{
    getPitchListings()
  },[yourAssets, listedOnly, notListedOnly, appliedFilters, searchQuery])



  useEffect(() => {

    if (yourAssetsFilters.yourAssets && !searchQuery){
      getPitchListings()
    }

  }, [stake_address])


  /*
  useEffect(() => {
    console.log(tokens)
  },[tokens])
  */

  function getPlot(cur_asset_id, color, enablePointers) {

      //console.log(cur_asset_id)


        let cur_coord = pitches_meta[cur_asset_id]['coordinates']
        let cur_size = pitches_meta[cur_asset_id]['size']
        let cur_zone = pitches_meta[cur_asset_id]['zone']




        cur_coord = cur_coord.split('(')[1]
        cur_coord = cur_coord.split(')')[0]

        let cur_x = parseInt(cur_coord.split(',')[0], 10) + 662
        let cur_y = -parseInt(cur_coord.split(',')[1], 10) + 442

        //temp.push({"coord":cur_coord, "size":cur_size})

        //setPlot([{'position':[plotPosition[0],plotPosition[1],plotPosition[2]+0.01],'size':[plotSize[0] + 0.001,plotSize[1] + 0.001,plotSize[2]+ 0.001],'id':0}])
        
        let plotSize = plotSizes[cur_size]

        
        let positionX = cur_x * (res/1024)/res * 64 - 64/2 + plotSize[0] / 2 + 0.0075
        let positionY = 64/2 - cur_y * (res/1024)/res * 64 - plotSize[1] / 2 - 0.0075
        const positionZ = 0 + 0.01

        let cur_color = 0x64dd17

        if (color){
          
          cur_color = color

        }else{

          let test_color = '#60fa00'

          if (cur_zone == 'Sonic Village'){
            //cur_color = '#EFFD5F'
            cur_color = '#0062ff'
          } else if (cur_zone == 'Baked Nation'){
            //cur_color = '#EE4B2B'
            cur_color = '#0062ff'
          } else if (cur_zone == 'Underworld'){
            //cur_color = '#007bff'
            cur_color = '#0062ff'
          }

        }
        

       let cur_plot = {
          'position': [positionX, positionY, positionZ],
          'size': [plotSize[0] + 0.001, plotSize[1] + 0.001, plotSize[2]+ 0.001],
          'id': cur_asset_id,
          'color': cur_color,
          'pointer': enablePointers
        }
      

      return cur_plot
  }


  async function getUserPitches(){

    let userPitches = []

    let query_url = 'https://claymarket.io/api/filter/listings/?collection=pitches' + '&stake_address=' + stake_address

    let asset_listing_info = await fetch(query_url).then(response => response.json())
                                   .then(data => data)


    if ('listings' in asset_listing_info){
      asset_listing_info = asset_listing_info['listings']
    }else{
      setPlots([])
      return 
    }


    for (let i = 0; i < asset_listing_info.length; i++){

      let cur_asset_listing = asset_listing_info[i]
      let asset_id = cur_asset_listing['asset_id']

      let ada_price = cur_asset_listing['ada_price']
      let clay_price = cur_asset_listing['clay_price']
     
      if (asset_id.slice(0,56) == pitches_policy){

        let cur_size = pitches_meta[asset_id]['size']
        let cur_zone = pitches_meta[asset_id]['zone']

        let cur_plot_info = {
          size: cur_size,
          zone: cur_zone
        }


        let filtersOk = true


        if (ada_price > 0 || clay_price > 0){
           if (yourAssets && !listedOnly){
            filtersOk = false
            continue
           }
        }else{
          if (yourAssets && !notListedOnly){
            filtersOk = false
            continue
           }
        }



        for (let k in appliedFilters){
        //console.log(k)


        if (k in cur_plot_info){
          //console.log(cur_plot_info[k])
          //console.log(appliedFilters[k])
          if (!appliedFilters[k].includes(cur_plot_info[k])){
            //console.log('FILTERS FAIL')
            filtersOk = false
            break
          }
        }
      }


        if (filtersOk){
            let cur_plot = getPlot(asset_id, 0x833d7e, true)
            userPitches.push(cur_plot)
        }

      } 
  
    }
    //console.log(userPitches)
    setPlots(userPitches)

    setPitchesFound(userPitches.length)
  }


  async function getPitchListings(){


    if (yourAssets && !searchQuery){
      getUserPitches()
      return 
    }

    //TEST ONLY
    let clay_token_price = clayPrice

    let query_url = 'https://claymarket.io/api/filter/listings/?collection=pitches'

    if (searchQuery){
      query_url += searchQuery
    }

    //console.log(query_url)


    let asset_listing_info = await fetch(query_url).then(response => response.json())
                                     .then(data => data)
    if ('listings' in asset_listing_info){
        asset_listing_info = asset_listing_info['listings']
    }else{
      return 
    }


    let temp = []

    for (let i = 0; i < asset_listing_info.length; i++){

      let cur_asset_id = asset_listing_info[i]['asset_id'] 

      //console.log('PITCHES')
      //console.log(cur_asset_id)
      
      let cur_size = pitches_meta[cur_asset_id]['size']
      let cur_zone = pitches_meta[cur_asset_id]['zone']

      let cur_ada_price = parseInt(asset_listing_info[i]['ada_price'], 10)
      let cur_clay_price = parseInt(asset_listing_info[i]['clay_price'], 10)

      let converted_price = Math.max(cur_ada_price, cur_clay_price * clay_token_price * 100) 


      let cur_plot_info = {
        size: cur_size,
        zone: cur_zone
      }


      let filtersOk = true


      for (let k in appliedFilters){
        //console.log(k)

        if (k in cur_plot_info){
          //console.log(cur_plot_info[k])
          //console.log(appliedFilters[k])
          if (!appliedFilters[k].includes(cur_plot_info[k])){
            //console.log('FILTERS FAIL')
            filtersOk = false
            break
          }
        }
      }


      if ('clay_only' in appliedFilters && appliedFilters['clay_only']){
        if (cur_clay_price > 0 && cur_ada_price <= 5000000){
          //do nothing
        }else{
          filtersOk = false
        }
      }


      if ('max_price' in appliedFilters){
        if (converted_price > appliedFilters['max_price']){
          filtersOk = false
        }
      }


       if ('min_price' in appliedFilters){
        if (converted_price < appliedFilters['min_price']){
          filtersOk = false
        }
      }



      //console.log(converted_price)
      //console.log(appliedFilters['max_price'])
      //console.log(appliedFilters['min_price'])

     

      if (cur_asset_id in pitches_meta && filtersOk){

        let cur_plot = getPlot(cur_asset_id, '', false)

        
        temp.push(cur_plot)
          
      }
      
    }

    //console.log(temp)
    setPlots(temp)
    setPitchesFound(temp.length)

  }




  return (

    <div className="Map-App">

    <div style={{position:'absolute', bottom:'20px', right:'20px', zIndex:'3000',
        height:'55px', display:'flex', flexDirection:'column', justifyContent:'space-between'}}>

          <div className='Zoom-btn'

          onClick={()=>{
              if (cameraPositionZ > 2){
                setCameraPositionZ(cameraPositionZ - 2)
                setZoomClicked(true)
                //console.log(cameraPositionZ)
              }
            }}
          >
            <div className='Zoom-text' style={{marginTop:'-4px'}}>
              +
            </div>
          </div>

          <div className='Zoom-btn'
          onClick={()=>{
              if (cameraPositionZ < 30){
                setCameraPositionZ(cameraPositionZ + 2)
                setZoomClicked(true)
                //console.log(cameraPositionZ)
              }}}
          >
           <div className='Zoom-text' style={{marginTop:'-4px'}}>
              -
            </div>
          </div>

           </div>

      <div id="canvas-container" className="Map">

      
        <Canvas camera={{position:[10, 8, 32],far:50}} >

       

        <Controls cameraPositionZ={cameraPositionZ} 
                  setCameraPositionZ={setCameraPositionZ} 
                  zoomClicked={zoomClicked}
                  setZoomClicked={setZoomClicked}
        />

        <ambientLight intensity={0.1} />
        <directionalLight color="white" position={[0,0,5]} />
          
        <Suspense fallback={

            null
    
          } >
              <MapPlane opacityFilter={pitchesFilters.opacityFilter} onPlotClick={onPlotClick} setSelectedPlot={setSelectedPlot} setRightSideBarOpen={setRightSideBarOpen} setSelectedPlot={setSelectedPlot} setPlot={setPlot} />

            </Suspense>
         

          {plot ? plot.map(cur_plot => (
        <group position={cur_plot.position} key={cur_plot.id}>
  <mesh>
          <boxGeometry attach="geometry" args={[cur_plot.size[0],cur_plot.size[1],cur_plot.size[2] + heightPointer]} />
          <meshBasicMaterial color={0xff4569} />
        </mesh>
  
  {circlePointer ? 
        <line geometry={lineGeometry} >
          <lineBasicMaterial attach="material" color={0xab003c} />
        </line>

          : null}

  </group>

  )) : null}


{/* 
  0x833d7e,
  0x64dd17,
  0x283457
*/}
    
        {plots ? plots.map(cur_plot => (

   <group position={cur_plot.position} key={cur_plot.id}>


  {cur_plot.pointer || plots.length <= 50 ? 
        <mesh>
           <boxGeometry attach="geometry" args={[cur_plot.size[0],cur_plot.size[1],cur_plot.size[2] + heightPointer]} />
          <meshBasicMaterial color={cur_plot.color} />
        </mesh>
  :
          <mesh>
          <boxGeometry attach="geometry" args={[cur_plot.size[0],cur_plot.size[1],cur_plot.size[2]]} />
          <meshBasicMaterial color={cur_plot.color} />
        </mesh>
      }

    {circlePointer && cur_plot.pointer || plots.length <= 50 ? 
        <line geometry={lineGeometry}>
          <lineBasicMaterial attach="material" color={cur_plot.color} />
        </line>
        : null}

        </group>


          )) : null}
        
           
        </Canvas>

      </div>
    </div>
  
  );
}

export default ClayMap;