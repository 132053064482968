import React from 'react'
import { useState, useContext, useEffect } from 'react';

import {Buffer} from 'buffer'

import useMediaQuery from '@mui/material/useMediaQuery';

import {MainClaimContext} from './ClaimContext'
   

import { useTheme } from '@mui/material/styles';

import './CnButton.css'

import './CN-CARD.css'
import './GC-CARD.css'

import './InputStyle.css'

import styled from 'styled-components'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import Divider from '@mui/material/Divider';



import './VotePopUp.css'

import BuildTransaction from './BuildTransaction'


import { ToastContainer, toast } from 'react-toastify';


import cn_rarity from '../cn_rarity.json'
import gc_rarity from '../gc_rarity.json'


const jpg_store_v2 = 'addr1zxgx3far7qygq0k6epa0zcvcvrevmn0ypsnfsue94nsn3tvpw288a4x0xf8pxgcntelxmyclq83s0ykeehchz2wtspks905plm'
const clay_market = 'addr1zxgx3far7qygq0k6epa0zcvcvrevmn0ypsnfsue94nsn3tgvp7yludr26w44pjv927202tt7ayffdwxjj688k690f03sr4chmz'



const SearchInput = styled.span`
  
    display:inline-block;
    width: 100%;
    margin-left: ${props => props.marginLeft};
    background: transparent;
  
`



  const ButtonText = styled.h1`
    position: relative;
    font-size: 1.8em;
    font-family: 'shortstack';
    letter-spacing: 0.1em;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    color: #fff;
    z-index: 4;
  `


  const pants_policy_id = '90a650d9ba123e2c31fa9c7ae22d1cb9308f6d609603bcfa52497771'


  const cardCollections = {
    "40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728":"cn",
    "94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768":"gc",
    "13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e":"pitches"
  }

    const assetBaseName = {
    "40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728":"ClayNation",
    "94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768":"ClayCharlotte",
    "13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e":"ClayNationPitch"
  }


  const cardThemes = {
    "cn":{title: "Clay Nation", image: 'CN_CARD.svg', boxShadow: "#FF9C83", color:"bisque"},
    "gc":{title: "CN x Good Charlotte", image: 'GC_CARD.svg', boxShadow: "#53C9EE", color: "bisque"},
    "pitches":{title: "Pitches at Clay Nation", image: 'PITCHES_CARD.svg', boxShadow: "#657389", color: "bisque"},
    "other":{title: "Clay Nation", image: 'CN_CARD.svg', boxShadow: "#FF9C83", color:"bisque"}
  }


function SellPopUp(props) {

  const {address, stake_address, activeWallet, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)
 

  const theme = useTheme();
  const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesM = useMediaQuery(theme.breakpoints.up('md'));
  const matchesL = useMediaQuery(theme.breakpoints.up('lg'));


  const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)

  const sellAsset = props.sellAsset
  const setSellAsset = props.setSellAsset

  //const activeWallet = props.curActiveWallet

 
  const setIsAlertOpen = props.setIsAlertOpen
  const setSubmittedTx = props.setSubmittedTx


  const [clayExchangeRate, setClayExchangeRate] = useState(0)

  const [clayPrice, setClayPrice] = useState('')

  const [searchClassName, setSearchClassName] = useState('inputField')

  const [priceSufficient, setPriceSufficient] = useState(false)

  const [assetStatus, setAssetStatus] = useState(sellAsset ? sellAsset.assetStatus:'')

  const [accumulatedToken, setAccumulatedToken] = useState(-1)

  const [priceCurrency, setPriceCurrency] = useState('')

  const [isWearable, setIsWearable] = useState(false)



  useEffect(() => {

    if (sellAsset.hasOwnProperty('asset_id') && sellAsset.asset_id){
        try{
            if (sellAsset.asset_id.slice(0,56) === pants_policy_id){
                setIsWearable(true)
            }else{
                setIsWearable(false)
            }
        }catch{

        }
    }

  },[sellAsset])



  useEffect(() => {

    try{

      let cur_price = sellAsset?.assetPrice
      let cur_clay_price = cur_price.clay_price
      let cur_ada_price = cur_price.ada_price

      if (cur_ada_price > 5000000 && cur_clay_price == 0){
        setPriceCurrency('ada')
      }

      if (cur_clay_price > 0 && cur_ada_price <= 5000000){
        setPriceCurrency('clay')
      }

    }catch{

    }

  },[sellAsset?.assetPrice])




  useEffect(()=>{
    //console.log('SELL ASSET')
    //console.log(sellAsset)


    if (sellAsset && sellAsset.collection != 'pitches'){
        if (sellAsset.hasOwnProperty('accumulatedToken')){
            setAccumulatedToken(sellAsset.accumulatedToken)
        }
        getAccumulatedTokens(sellAsset.asset_id)
    } 


  },[sellAsset])


  useEffect(()=>{
    //console.log(stake_address)
  },[stake_address])






  useEffect(()=>{


        if (sellAsset){

            //console.log(stake_address)
            //console.log(sellAsset.owner_stake_address)


            let temp_assetStatus = ''


            if (stake_address == sellAsset.owner_stake_address){

              //console.log('SAME')

              if ('assetPrice' in sellAsset && ((parseInt(sellAsset.assetPrice['ada_price'],10) > 0) || (parseInt(sellAsset.assetPrice['clay_price'], 10) > 0)) ){
                temp_assetStatus = 'updatable'
              }
              else{
                temp_assetStatus = 'sellable'
              }
            
            }else{

              //console.log('NOT SAME')

              if ('assetPrice' in sellAsset && ((parseInt(sellAsset.assetPrice['ada_price'],10) > 0) || (parseInt(sellAsset.assetPrice['clay_price'], 10) > 0)) ){
                temp_assetStatus = 'listed'
              }
              else{
                temp_assetStatus = 'not listed'
              }
            }




        sellAsset.assetStatus = temp_assetStatus

        setAssetStatus(temp_assetStatus)

        //setSellAsset(sellAsset)
    }



},[stake_address])


  async function getClayTokenPrice(){

    let query_url = 'https://claymarket.io/api/claytoken/price/'

    let temp = await fetch(query_url).then(response => response.json())
                                   .then(data => data)

    let temp_exchange_rate = 0                           
    if ('clay_price' in temp){
        temp_exchange_rate = parseFloat(temp['clay_price'])
    }

    setClayExchangeRate(temp_exchange_rate)
  }


  function checkClayPrice(inputValue){

    //console.log(inputValue)

    const temp_clayPrice = parseInt(inputValue, 10)
    //console.log(temp_clayPrice)

    if (inputValue == ''){
        
        return ''
    }

    if (!isNaN(inputValue) && 
         parseInt(Number(inputValue)) == inputValue && 
         !isNaN(parseInt(inputValue, 10))){
         
         
         if (temp_clayPrice <= 100000000){
            return String(temp_clayPrice)
         }else{
            notifyWarn('Digit limit reached!')
            return clayPrice
         }
    }

    return ''

  }



  function getSerial(pValue){

    let policy = pValue.slice(0,56)
    let temp = pValue.slice(56,)
    temp = Buffer.from(temp, 'hex').toString('utf8')
    
    let serial = ''
    if (policy in assetBaseName){
        serial = temp.slice(assetBaseName[policy].length, )
    }

    return serial
  
  }


  function AdaEquivalent(props){

    const pValue = props.pValue

    useEffect(()=>{
        getClayTokenPrice()
    },[])


    return(
        <div style={{color:'bisque', fontFamily:'ShortStack', marginTop:'5px', marginBottom:'5px'}}>
           (<span style={{fontWeight:'bold'}}>{Math.round(clayExchangeRate * pValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> ADA)
        </div>

    )

  }



  function PriceBreakdown(props){

    const pValue = props.pValue


    return (
            <div style={{fontFamily:'ShortStack', color:'bisque', marginTop:'10px'}}>

                <div style={{display:'flex', flexDirection:'column', width:'100%', flexWrap:'wrap', 
                            justifyContent:'center', marginTop:'5px', marginBottom:'5px'}}>
                                            
                    <div style={{fontWeight:'bold'}}>
                        Market Fee 0%: 
                    </div>

                     <div>
                        {(Math.round(pValue * 0.0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} $CLAY
                     </div>
                </div>


                {/*
                <div style={{display:'flex', flexDirection:'column', width:'100%', flexWrap:'wrap', justifyContent:'center',
                            marginTop:'5px', marginBottom:'5px'}}>
                                            
                    <div style={{fontWeight:'bold'}}>
                        Fixed ADA Fee: 
                    </div>

                    <div>
                        2.5 ADA
                    </div>
                </div>
                */}


                <div style={{display:'flex', flexDirection:'column', width:'100%', flexWrap:'wrap', justifyContent:'center',
                            marginTop:'5px', marginBottom:'5px'}}>
                                            
                    <div style={{fontWeight:'bold', display:'flex', justifyContent:'center'}}>

                        <div style={{width:'180px'}}>
                            Amount received if sold:
                        </div>
                    </div>

                    <div>
                       {(pValue - Math.round(pValue * 0.0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} $CLAY
                    </div>

                </div>

            </div>
    )
  }


  function ListingWarning(){


    return(
        <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap', 
                     justifyContent:'center', alignItems:'center', 
                     backgroundColor:'rgba(255,255,255,0.05)', borderRadius:'20px',
                     paddingTop:'10px', paddingBottom:'20px', color:'bisque', fontFamily:'ShortStack',
                     marginTop:'10px'
                    }}>
                                        
            <div style={{display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', fontWeight:'bold'}}>
                <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png' style={{width:'45px'}} />
                    IMORTANT!                                    
            </div>
                <div style={{width:'80%'}}>
                    You can only list or update your listings
                    in $CLAY token.
                </div>                   
        </div>

        )
  }


/*
function PriceInput(){

    const [searchClassName, setSearchClassName] = useState('searchFieldPressed')
    const [searchVal, setSearchVal] = useState('')



    return (


 <div className={searchClassName} style={{marginTop:'0px', marginBottom:'20px', marginLeft: '0px', width:'340px'}}>
 
    <SearchInput>
        <input
          id="searching"
          type="text"
          value={clayPrice}
          placeholder={0}
          

          onChange={(e) => { 
            let cur_value = e.target.value
             checkClayPrice(cur_value)
            
          }}

         
       />

</SearchInput>
Press
</div>


    )
}
*/





function AssetThumbnail(){


    return(
        <div style={{height:'100%', width:'100%', backgroundColor:'', marginTop:'10px'}}>
           
            {isWearable ?

            <div style={{display:'flex', justifyContent:'center', color:'bisque', fontFamily:'ShortStack', fontSize:'22px', fontWeight:'bold'}}>
                
                {sellAsset.meta.name}
            </div>

            :

                <div style={{display:'flex', justifyContent:'center', color:'bisque', fontFamily:'ShortStack', fontSize:'22px', fontWeight:'bold'}}>
                    <div>{( sellAsset.collection == 'pitches' ? 'PITCH' : sellAsset.collection).toUpperCase()}</div>
                    <div>#{getSerial(sellAsset.asset_id)}</div>
                </div> 
            }

            <img src={sellAsset.image} style={{width:'25%', minWidth:'180px',maxWidth:'300px',height:'auto', borderRadius:'10px'}} />                       
        </div>
    )
}



function AssetProperties(props){

    let collection = props.collection
    let meta = props.meta


    function displayProp(category, trait){

        let rarity = ''
        let totalAssets = 1
        let rarity_dict = {}

        if (collection == 'cn'){ 
            rarity_dict = cn_rarity
            totalAssets = 10000
        }else if (collection == 'gc'){
            rarity_dict = gc_rarity
            totalAssets = 9427
        }

        if (category in rarity_dict && trait in rarity_dict[category]){
            rarity = rarity_dict[category][trait]
        }

        if (rarity && totalAssets > 1){
            rarity = Math.round( (rarity / totalAssets) * 10000 ) / 100
            rarity = '(' + rarity + '%)'
        }


        return(
            <span>

                <span>
                    {trait + ' '} 
                </span>

                <span>
                    {rarity}
                </span>

            </span>
        )

    }


    return(
            <div style={{maxHeight:'250px', width:'70%', backgroundColor:'', color:'bisque', fontFamily:'ShortStack',
                                             backgroundColor:'rgba(255,255,255,0.05)', borderRadius:'20px', marginTop:'10px', paddingTop:'10px'}}>
                
            <div style={{paddingBottom:'2px', fontWeight:'bold', fontSize:'18px'}}>
                Properties:
            </div>

                <div className={sellAsset.collection.toUpperCase()+"-properties"} style={{overflow:'scroll',  maxHeight:'180px', marginTop:'0px',backgroundColor:'', paddingTop:'6px', paddingBottom:'6px', backgroundColor:''}}>
                                    
                        {Object.keys(meta).map(element => (
                            element == 'image' || element == 'collection' ? null :

                            <div key={sellAsset.asset_id + element + sellAsset.meta[element]} style={{marginTop:'2px', marginBottom:'2px'}}>
                                <span className={sellAsset.collection.toUpperCase()+ "-clay-properties"}>{element}</span> 
                                <span className={ sellAsset.collection == 'pitches' ? "CN-clay-percentage" :  sellAsset.collection.toUpperCase() + "-clay-percentage"}>{displayProp(element, meta[element] ? meta[element] : 'None')}</span>
                            </div>
                                        
                           ))}
                                             
                            

                </div>
            </div>
    )

}



    async function getAccumulatedTokens(asset_id){

        let currentSlot = await getCurrentSlot()


        let baking_info = await fetchBakingInfo(asset_id, currentSlot)

        let temp_accumulatedToken;

        if (baking_info['processing_tx'] == null){
            //console.log('YAY')

            //console.log(baking_info)

            let rate = baking_info['rate']

            let bakingSlot = baking_info['slot']

            let daily_rate = rate / 86400

            let temp = daily_rate * ( parseInt(currentSlot, 10) - parseInt(bakingSlot, 10) ) - daily_rate * 1209600

            temp = Math.floor(temp)

            if (temp < 0){
                temp_accumulatedToken = 'Baking'
                //setAccumulatedToken('Baking')
            }else{
                temp_accumulatedToken = temp
                //setAccumulatedToken(temp)
            }

        }else{
            temp_accumulatedToken = 'Processing'
            //setAccumulatedToken('Processing')
        }


      
        setAccumulatedToken(temp_accumulatedToken)
        
    }



    async function getCurrentSlot(){
        let slot = await fetch('https://claymarket.io/api/current/slot/').then(response => response.json())
                                                                      .then(data => data)
        slot = parseInt(slot.slot, 10)

        return slot
}

    async function fetchBakingInfo(asset_id, currentSlot){

        let fetched_records = await fetch('https://clayapi.com/filtering/asset/?page=1&slot='+currentSlot+'&asset_id='+asset_id)
          .then((response) => response.json())
          .then((data) => data);

        fetched_records = fetched_records['assets'][0]
        return fetched_records

    }



function AccumulatedToken(props){


    const prop_accumulatedToken = props.accumulatedToken


    return(

       
    <div style={{width:'70%'}}>

        {accumulatedToken != 0 && sellAsset.collection != 'pitches' && !isWearable ? 

        <div>

             <div style={{minHeight:'60px', width:'100%', backgroundColor:'', display:'flex', flexDirection:'column',
                                                    justifyContent:'center', alignItems:'center', backgroundColor:'rgba(255,255,255,0.1)', 
                                                    borderRadius:'20px', marginTop:'10px', paddingTop:'10px', paddingBottom:'15px'}}>
                
                <div style={{color:'bisque', fontWeight:'bold', fontFamily:'ShortStack', fontSize:'18px'}}>
                    Accumulated Token
                </div>

                <div style={{backgroundColor:'', position:'relative', marginTop:'10px'}}>

                <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'2px', height:'30px', backgroundColor:''}}>
                  <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/TokenSpace.png' style={{position:'absolute', width:'110px', marginLeft:'0px'}} />
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', marginLeft:'-5px'}}>
                     <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CLAY_TOKEN_220x220.png' style={{width:'20px', marginRight:'2px', zIndex:'100'}} />
                     <span className={sellAsset.collection.toUpperCase() + "-tokens"}>{accumulatedToken > 0 ? accumulatedToken : accumulatedToken == -1 ? '-' : 'baking'}</span>
                    </div>
                </div>

                </div>
            </div>
            </div>

            : null}

    </div>
            
    )
}





function ClosePopUp(){


    return(

            <button className='close-btn' 

            onClick={() => {
                            setSellAsset(null)
                            setClayPrice('')
             }}>X</button> 
    )


}



function displayPrice(price){
  let ada_price = price['ada_price']
  let clay_price = price['clay_price']



  if (ada_price > 5000000){
    return(
      <div style={{display:'flex', alignItems:'center'}}>
      <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Cardano_white.svg' style={{width:'25px', zIndex:'0'}} />
        {(ada_price/1000000).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </div>
    )
  }else if(clay_price > 0){
    return(
      <div style={{display:'flex', alignItems:'center'}}>
        <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CLAY_TOKEN_220x220.png' style={{width:'25px', zIndex:'0'}} />
            {(clay_price/10000).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </div>
      )
  }
}


  function displayAmount(amount, precision){
    if (amount < 10000){
      return String(amount)
    }else if (amount < 1000000){
      return String((amount / 1000).toFixed(precision)) + 'K'
    }else if (amount < 1000000000){
      return String((amount / 1000000).toFixed(precision)) + 'M'
    }
}



useEffect(() => {

    if (clayPrice >= 1000){
        setPriceSufficient(true)
    }else{
         setPriceSufficient(false)
    }

},[clayPrice])



  return (
    
    <>
    {sellAsset ? 

    <div>
        <Grid container justifyContent="center" alignItems="center">
            <div className='popup' style={{width:'100%', height:'100%', zIndex:'1350', backgroundColor:'',  backdropFilter: 'blur(25px)'}}  > 
                <Grid container justifyContent="center" alignItems="center" 
                style={{display:'flex',position:'absolute', top:'50px', height:'100%'}}>


                    <div className='popup-inner' style={{backgroundColor:'', maxWidth:'500px', maxHeight:'800px'}}>

                        
                            <ClosePopUp />


                            <div style={{width:'100%', height:'100%', backgroundColor:'', display:'flex', 
                                        flexDirection:'column', justifyContent:'space-between', 
                                        alignItems:'center', overflow:'scroll'}}>

                            <div style={{height:'220px', width:'70%', backgroundColor:'', display:'flex', flexDirection:'column',
                                justifyContent:'center', alignItems:'center', backgroundColor:'rgba(255,255,255,0.05)', 
                                borderRadius:'20px', marginTop:'10px', paddingBottom:'30px'}}>
                                <AssetThumbnail />

                            </div>


                               { sellAsset && sellAsset.meta ?                          
                                
                                <AssetProperties meta={sellAsset.meta} collection={sellAsset.collection} />

                                :null}

                                
                               
                                <AccumulatedToken asset_id={sellAsset.asset_id} accumulatedToken={accumulatedToken} />
                              

                                {sellAsset.assetStatus && sellAsset.assetStatus == 'sellable' ?

                                /*SELL*/
                                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                                   
                                    <div style={{width:'70%'}}>

                                        
                                            <ListingWarning />
                                        
                                    </div>


                                        <div style={{minHeight:'250px', width:'70%', backgroundColor:'', display:'flex', flexDirection:'column',
                                                    justifyContent:'center', alignItems:'center', backgroundColor:'rgba(255,255,255,0.1)', 
                                                    borderRadius:'20px', marginTop:'10px', paddingTop:'10px', paddingBottom:'10px'}}>

                                            <div style={{height:'100%', width:'70%', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                        
                                                <AdaEquivalent  pValue={clayPrice} />

                                                    <form>

                                                        <div className={searchClassName} style={{marginTop:'0px', marginBottom:'20px', marginLeft: '0px', width:'200px'}}>
 
                                                            <SearchInput>
                                                            <input 
                                                                type="text" 
                                                                placeholder={0} 
                                                                value={clayPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}

                                                                onChange={(e) => {
                                                                    let cur_value = e.target.value
                                                                    cur_value = cur_value.replaceAll(",", "")

                                                                    //console.log('CUR VALUE')
                                                                    //console.log(cur_value)
                                                                    setClayPrice(checkClayPrice(cur_value))
                                                                }}

                                                             />


                                                             </SearchInput>


                                                             <img alt="$CLAY" src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CLAY_TOKEN_220x220.png' style={{width:'40px', color:'bisque', fontSize:'12px', position:'absolute', zIndex:'3000', right:'10px'}} />

                                                            </div>
                                                            </form>
                                                </div>


                                                <div style={{backgroundColor:''}}>

                                                {!priceSufficient  ?
                                                            <div style={{color:'#ff5a53', fontFamily:'ShortStack', fontWeight:'bold'}}>
                                                                Price needs to be > 1000 $CLAY
                                                            </div>

                                                            : null
                                                        }


                                                <PriceBreakdown pValue={clayPrice} />



                                                <BuildTransaction 
                                                            activeWallet={activeWallet}
                                                            sellAsset={sellAsset}
                                                            txInfo={{action:'sell', price:{lovelace: 2000000, clay: clayPrice*10000}, assets:[{unit:sellAsset.asset_id, quantity:1}]}} 
                                                            setIsAlertOpen={setIsAlertOpen} setSubmittedTx={setSubmittedTx}
                                                            setSellAsset={setSellAsset}
                                                            priceSufficient={priceSufficient}
                                                            />
                                                </div>

                                            </div>

                                        </div>

                                : sellAsset.assetStatus && sellAsset.assetStatus == 'updatable' ?

                                 <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

                                
                                <div style={{width:'70%'}}>
                                    <ListingWarning />
                                </div>



                                    <div style={{minHeight:'80px', width:'70%', backgroundColor:'', display:'flex', flexDirection:'column',
                                                    justifyContent:'space-between', alignItems:'center', backgroundColor:'rgba(255,255,255,0.05)', 
                                                    borderRadius:'20px', marginTop:'10px', paddingTop:'10px', paddingBottom:'10px'}}>

                                    <div style={{color:'bisque', fontWeight:'bold', fontFamily:'ShortStack', fontSize:'18px'}}>
                                       Current Price
                                    </div>

                                   <div className={"CN-price-tag-back-simple"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                                     <img src={priceCurrency === 'clay' ? 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'+sellAsset.collection.toUpperCase()+'_PRICE_TAG.svg' : priceCurrency ? 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/price_tag_cardano.png' : ''} style={{position:'absolute', width:'200px', marginLeft:'-40px', marginTop:'-20px', backgroundColor:''}} />
                                      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'60px', marginTop:'0px', 
                                                  width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                                        <div className={"CN-ADA-back"}>
                                          <div style={{color:'white'}}>{displayPrice(sellAsset.assetPrice)}</div> 
                                        </div>
                                      </div>
                                  </div>

                                  
                                  {

                                    sellAsset && !(sellAsset.address == clay_market || sellAsset.address == jpg_store_v2) ?



                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'100%'}}>

                                                        <div style={{color:'white', fontStyle: 'italic', fontFamily:'ShortStack', 
                                                                    fontSize:'14px', width:'250px', marginTop:'10px', marginBottom:'10px'}}>
                                                            
                                                            <span style={{color:'#ff5a53', fontSize:'16px', fontWeight:'bold'}}>NOTE! </span>
                                                            Please update your listing on JPG.STORE directly
                                                        </div>



                                                        <div style={{display:'flex', justifyContent: 'center'}}>
                                                        <button className={ sellAsset ? sellAsset['collection'].toUpperCase() + "-SellBtn" : "CN-SellBtn"} style={{transform: 'scale(0.75)', zIndex:'100'}} onClick={(event)=>{
                                                              event.stopPropagation()

                                                                let jpg_url = 'https://www.jpg.store/asset/' + sellAsset.asset_id + '/'
                                                                window.open(jpg_url, '_blank').focus();

                                                            }}>
                                                              <ButtonText style={{fontSize:'18px'}}>
                                                                JPG.STORE
                                                              </ButtonText>
                                                            </button>
                                                        </div>

                                                    </div>

                                    : null

                                  } 




                                  </div>



                                    {sellAsset && (sellAsset.address == clay_market || sellAsset.address == jpg_store_v2) ? 

                                        <div style={{minHeight:'250px', width:'70%', backgroundColor:'', display:'flex', flexDirection:'column',
                                                    justifyContent:'center', alignItems:'center', backgroundColor:'rgba(255,255,255,0.05)', 
                                                    borderRadius:'20px', marginTop:'10px', paddingTop:'10px', paddingBottom:'10px'}}>

                                            {/* UPDATE */}
                                            <div style={{height:'100%', width:'200px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                        
                                                <AdaEquivalent  pValue={clayPrice} />

                                                    {/*<PriceInput />*/}

                                                            <form>

                                                        <div className={searchClassName} style={{marginTop:'0px', marginBottom:'20px', marginLeft: '0px', width:'200px'}}>
 
                                                            <SearchInput>
                                                            <input 
                                                                type="text" 
                                                                placeholder={0} 
                                                                value={clayPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}

                                                                onChange={(e) => {
                                                                    let cur_value = e.target.value
                                                                    cur_value = cur_value.replaceAll(",", "")

                                                                    //console.log('CUR VALUE')
                                                                    //console.log(cur_value)
                                                                    setClayPrice(checkClayPrice(cur_value))
                                                                }}

                                                             />


                                                             </SearchInput>


                                                             <img alt="$CLAY" src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CLAY_TOKEN_220x220.png' style={{width:'40px', color:'bisque', fontSize:'12px', position:'absolute', zIndex:'3000', right:'10px'}} />

                                                            </div>
                                                            </form>
                                                        

                                                        <div style={{backgroundColor:''}}>


                                                        {!priceSufficient ?
                                                            <div style={{color:'#ff5a53', fontFamily:'ShortStack', fontWeight:'bold'}}>
                                                                Min price is 1000 $CLAY
                                                            </div>

                                                            : null
                                                        }



                                                        <PriceBreakdown pValue={clayPrice} />


                                                        <BuildTransaction 
                                                            activeWallet={activeWallet}
                                                            sellAsset={sellAsset}
                                                            txInfo={{action:'update', listing_utxo: sellAsset.tx_hash,price:{lovelace: 2000000, clay: clayPrice*10000}, assets:[{unit:sellAsset.asset_id, quantity:1}]}} 
                                                            setIsAlertOpen={setIsAlertOpen} setSubmittedTx={setSubmittedTx}

                                                            setSellAsset={setSellAsset}

                                                            priceSufficient={priceSufficient}
                                                            />
                                                        </div>

                                                        </div>


                                                        <div style={{borderTop: '1px solid bisque', width:'60%'}}>

                                                        </div>


                                                        {/* DELIST */}
                                                        <div style={{height:'100%', width:'200px', backgroundColor:'', justifyContent:'center', 
                                                                    alignItems:'center', display:'flex', flexDirection:'column'}}>

                                                            <BuildTransaction 
                                                                activeWallet={activeWallet}
                                                                sellAsset={sellAsset}
                                                                txInfo={{action:'delist', listing_utxo: sellAsset.tx_hash}} 
                                                                setIsAlertOpen={setIsAlertOpen} setSubmittedTx={setSubmittedTx}

                                                                setSellAsset={setSellAsset}

                                                                priceSufficient={priceSufficient}
                                                            />

                                                        </div>
                                                    </div>


                                                    : 


                                                    null


                                                }


                                                </div>


                                 : sellAsset.assetStatus && sellAsset.assetStatus == 'listed' ?
                                
                                    <div style={{width:'70%'}}>

                                    
                                    <div style={{minHeight:'140px', backgroundColor:'', display:'flex', flexDirection:'column',
                                                    justifyContent:'space-between', alignItems:'center', backgroundColor:'rgba(255,255,255,0.1)', 
                                                    borderRadius:'20px', marginTop:'10px', paddingTop:'10px', paddingBottom:'10px'}}>

                                    <div style={{color:'bisque', fontWeight:'bold', fontFamily:'ShortStack', fontSize:'18px'}}> 
                                       Current Price
                                    </div>

                                   <div className={"CN-price-tag-back-simple"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                                     <img src={priceCurrency === 'clay' ? 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'+sellAsset.collection.toUpperCase()+'_PRICE_TAG.svg' : priceCurrency ? 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/price_tag_cardano.png' : ''} style={{position:'absolute', width:'200px', marginLeft:'-40px', marginTop:'-20px', backgroundColor:''}} />
                                      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'60px', marginTop:'0px', 
                                                  width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                                        <div className={"CN-ADA-back"}>
                                          <div style={{color:'white'}}>{displayPrice(sellAsset.assetPrice)}</div> 
                                        </div>
                                      </div>
                                  </div>


                                 


                                  {sellAsset && Math.round(sellAsset.assetPrice['clay_price']) > 0 ?
                                  <div style={{fontFamily:'ShortStack', color:'bisque', marginTop:'10px'}}>
                                      <div style={{display:'flex', flexDirection:'column', width:'100%', flexWrap:'wrap', justifyContent:'center',
                                                marginTop:'5px', marginBottom:'5px'}}>
                                                                
                                        <div style={{fontWeight:'bold'}}>
                                          + Fixed ADA Fee: 
                                        </div>

                                        <div>
                                            {(sellAsset.assetPrice['ada_price']/1000000 - 2)} ADA
                                        </div>

                                    </div>

                                    </div>

                                : null}




                                    
                                    {/* BUY OR MAKE OFFER */}
                                    <div style={{height:'100%', width:'100%', backgroundColor:''}}>


                                        {

                                            sellAsset && (sellAsset.address == clay_market || sellAsset.address == jpg_store_v2) ?
                                        

                                        <BuildTransaction 
                                            activeWallet={activeWallet}
                                            sellAsset={sellAsset}
                                            txInfo={{action:'buy', listing_utxo: sellAsset.tx_hash}} 
                                            setIsAlertOpen={setIsAlertOpen} setSubmittedTx={setSubmittedTx}
                                            setSellAsset={setSellAsset}
                                            priceSufficient={priceSufficient}
                                            />


                                        : 


                                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'100%'}}>

                                            <div style={{color:'white', fontStyle: 'italic', fontFamily:'ShortStack', 
                                                        fontSize:'14px', width:'250px', marginTop:'10px', marginBottom:'10px'}}>
                                                
                                                <span style={{color:'#ff5a53', fontSize:'16px', fontWeight:'bold'}}>NOTE! </span>
                                                Please purchase from JPG.STORE directly
                                            </div>



                                            <div style={{display:'flex', justifyContent: 'center'}}>
                                            <button className={ sellAsset ? sellAsset['collection'].toUpperCase() + "-SellBtn" : "CN-SellBtn"} style={{transform: 'scale(0.75)', zIndex:'100'}} onClick={(event)=>{
                                                  event.stopPropagation()

                                                    let jpg_url = 'https://www.jpg.store/asset/' + sellAsset.asset_id + '/'
                                                    window.open(jpg_url, '_blank').focus();

                                                }}>
                                                  <ButtonText style={{fontSize:'18px'}}>
                                                    JPG.STORE
                                                  </ButtonText>
                                                </button>
                                            </div>

                                        </div>


                                        }


                                    </div>



                                    </div>


                                    {/*

                                     <div style={{height:'100%', width:'100%', backgroundColor:''}}>
                                        <form>
                                            <input 
                                                type="text" 
                                                placeholder={0} 
                                                value={clayPrice}
                                                onChange={(e) => {
                                                    let cur_value = e.target.value
                                                    checkClayPrice(cur_value)
                                                }}

                                             />
                                        </form>
                                    </div>



                                    <div style={{height:'100%', width:'100%', backgroundColor:''}}>
                                        <BuildTransaction 
                                            activeWallet={activeWallet}
                                            sellAsset={sellAsset}
                                            txInfo={{action:'make offer', listing_utxo: sellAsset.tx_hash,price:{lovelace: 2000000, clay: clayPrice*10000}, assets:[{unit:sellAsset.asset_id, quantity:1}]}} />
                                    </div>

                                    */}


                                    </div>

                                 :null}

                            </div>

                    </div>
                </Grid>
            </div>
        </Grid>
    </div>

    : null}
    </>
   
  )
}

export default SellPopUp